'use client';

import { Box, createListCollection, Flex, Portal } from '@chakra-ui/react';
import { Tag } from '@company/ui/components/ui/tag';
import { FieldValues } from '@company/ui/hooks/form';
import { SelectOption } from '@company/ui/types/select';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { Combobox } from '../combobox';
import { TagsInput, TagsInputControlProps } from '../tags-input';

export const TagComboboxFormInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  placeholder,
  helperText,
  helperProps,
  errorProps,
  hideError,
  size = 'md',
  backgroundColor = 'white',
  options,
  isRequired,
  isReadOnly,
  isMultiSelect = false,
  text
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder?: string;
  backgroundColor?: TagsInputControlProps['backgroundColor'];
  options: SelectOption<unknown>[];
  isMultiSelect?: boolean;
  text: {
    noOptionsFound: string;
  };
}) => {
  const [inputValue, setInputValue] = React.useState<string>('');

  const valueToItem = React.useMemo(
    () =>
      options.reduce(
        (acc, option) => {
          acc[option.value] = option;
          return acc;
        },
        {} as Record<string, SelectOption<unknown>>
      ),
    [options]
  );
  const filteredCollection = React.useMemo(() => {
    return createListCollection({
      items: options
        .filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase()))
        .map(option => ({
          label: option.label,
          value: option.value.toString()
        }))
    });
  }, [options, inputValue]);

  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={label}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <Controller
        control={form.control}
        name={name}
        render={({ field }) => {
          const selectedValues: string[] = React.useMemo(() => {
            return field.value === null || field.value === undefined
              ? []
              : !isMultiSelect
                ? [field.value]
                : field.value;
          }, [field.value, isMultiSelect]);

          const removeItem = (itemValue: string) => {
            if (isMultiSelect) {
              field.onChange(selectedValues.filter(item => item !== itemValue));
            } else {
              field.onChange(null);
            }
          };

          return (
            <Combobox.Root
              asChild
              collection={filteredCollection}
              inputValue={inputValue}
              onInputValueChange={({ inputValue }) => setInputValue(inputValue)}
              onValueChange={({ value }) => {
                field.onChange(!isMultiSelect ? value[0] : value);
              }}
              value={selectedValues}
              readOnly={isReadOnly}
              disabled={isDisabled}
              openOnClick={true}
              multiple={isMultiSelect}
              closeOnSelect={true}
              selectionBehavior="clear"
            >
              <Combobox.Context>
                {comboBoxApi => (
                  <TagsInput.Root>
                    <TagsInput.Context>
                      {api => (
                        <>
                          <TagsInput.Control
                            {...comboBoxApi.getControlProps()}
                            backgroundColor={backgroundColor}
                            // Avoid changing the height when there are no selected values
                            py={selectedValues.length === 0 ? undefined : 1}
                          >
                            {selectedValues.map((value, index) => (
                              <TagsInput.Item key={index} index={index} value={value}>
                                <TagsInput.ItemPreview asChild>
                                  <Tag
                                    closable={!isReadOnly && !isDisabled}
                                    onClose={() => removeItem(value)}
                                    size={size}
                                  >
                                    {valueToItem[value]?.label}
                                  </Tag>
                                </TagsInput.ItemPreview>
                                <TagsInput.ItemInput />
                                <TagsInput.HiddenInput />
                              </TagsInput.Item>
                            ))}
                            {!isReadOnly && !isDisabled && (
                              <TagsInput.Input
                                {...comboBoxApi.getInputProps()}
                                placeholder={placeholder}
                                w={'full'}
                              />
                            )}
                          </TagsInput.Control>
                          <Portal>
                            <Combobox.Positioner zIndex={'9999 !important'}>
                              <Combobox.Content h={'full'} overflowY={'auto'} fontSize={'sm'}>
                                <Combobox.ItemGroup gap={1}>
                                  {filteredCollection.items.map(item => (
                                    <Combobox.Item key={item.value} item={item} py={1}>
                                      <Flex
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        px={3}
                                      >
                                        <Combobox.ItemText>
                                          <Flex gap={2} alignItems={'center'}>
                                            {valueToItem[item.value]!.startElement}
                                            {item.label}
                                          </Flex>
                                        </Combobox.ItemText>
                                        <Combobox.ItemIndicator>✓</Combobox.ItemIndicator>
                                      </Flex>
                                    </Combobox.Item>
                                  ))}
                                  {filteredCollection.items.length === 0 && (
                                    <Box color={'gray.500'} px={2} py={1}>
                                      {text.noOptionsFound}
                                    </Box>
                                  )}
                                </Combobox.ItemGroup>
                              </Combobox.Content>
                            </Combobox.Positioner>
                          </Portal>
                        </>
                      )}
                    </TagsInput.Context>
                  </TagsInput.Root>
                )}
              </Combobox.Context>
            </Combobox.Root>
          );
        }}
      />
    </FormInput>
  );
};
