import { json2xml, xml2json } from 'xml-js';
import {
  COST_CALCULATION_TABLE_FIELDS,
  CostCalculationTableField,
  CostCalculationTypeTableFieldSelectOptionId,
  WORK_ITEM_TABLE_FIELDS,
  WorkItemTableField
} from '../../../types/data-converter';
import { ToUploadFile } from '../../../types/files';
import { ExportTable, ExportTableField, TableRowDto } from '../../../types/table';
import { removeNullish } from '../../../types/utils';
import { isTableRowLinkedRowTypeArray } from '../../table';
import { findXmlElementByName, textXmlConverter, XmlDocument } from '../xml-converter';
import { XmlWBSItemEstDetailsConverter } from './converter';
import { WBSItemEstDetailsItem } from './types/cost-estimation';
import { cleanReferenceNumber, getItwoMajorWbsMapFromXml } from './utils';

export const exportTableToITwoCostCalculationFile = ({
  projectTable,
  priceCatalogTable,
  recommendationFieldId,
  projectXml
}: {
  projectTable: ExportTable;
  priceCatalogTable: ExportTable;
  recommendationFieldId: string;
  projectXml: string;
}): ToUploadFile => {
  let projectXmlJson: XmlDocument = JSON.parse(xml2json(projectXml, { compact: false, spaces: 2 }));

  const fieldReferenceIdToFieldId = projectTable.fields.reduce(
    (acc, field) => {
      if (field.referenceId && field.referenceId in WORK_ITEM_TABLE_FIELDS) {
        acc[field.referenceId as WorkItemTableField] = field.id;
      }
      return acc;
    },
    {} as Record<WorkItemTableField, string>
  );

  // Then we need to update the price calculation
  projectXmlJson = updatePriceCalculation({
    projectXmlJson,
    projectTable: projectTable,
    priceCatalogTable,
    recommendationFieldId,
    fieldReferenceIdToFieldId
  });

  return {
    content: json2xml(JSON.stringify(projectXmlJson), { compact: false, spaces: 2 }),
    name: `${projectTable.name}.xml`,
    extension: 'xml'
  };
};

const updatePriceCalculation = ({
  projectXmlJson,
  projectTable,
  priceCatalogTable,
  recommendationFieldId,
  fieldReferenceIdToFieldId
}: {
  projectXmlJson: XmlDocument;
  projectTable: ExportTable;
  priceCatalogTable: ExportTable;
  recommendationFieldId: string;
  fieldReferenceIdToFieldId: Record<WorkItemTableField, string>;
}): XmlDocument => {
  const majorWbsMap = getItwoMajorWbsMapFromXml(projectXmlJson);

  if (majorWbsMap) {
    Object.entries(majorWbsMap).forEach(([wbsName, wbs]) => {
      const itemsElement = findXmlElementByName(wbs.elements, 'ITEMS');
      if (!itemsElement) {
        return;
      }
      itemsElement.elements = itemsElement.elements?.map(item => {
        if (item.type !== 'element') {
          return item;
        }

        const referenceNumber = cleanReferenceNumber(
          textXmlConverter.toJson(
            findXmlElementByName(item.elements, 'NameWBSItem')?.elements?.[0]
          ) ?? ''
        );

        if (!referenceNumber) {
          return item;
        }

        const projectTableRow = projectTable.rows.find(
          row =>
            row[fieldReferenceIdToFieldId['referenceNumber']] === referenceNumber &&
            wbsName === row[fieldReferenceIdToFieldId['boqName']]
        );
        const projectTableRowValue = projectTableRow?.[recommendationFieldId];

        if (!projectTableRowValue || !isTableRowLinkedRowTypeArray(projectTableRowValue)) {
          return item;
        }

        const linkedRowIds =
          projectTableRowValue.linkedRows?.map(linkedRow => linkedRow.linkedRowId) ?? [];
        const recommendedPriceCatalogTableRows = priceCatalogTable.rows.filter(row => {
          return linkedRowIds.includes(row.id);
        });

        let costCalculationFields: ExportTableField[] = [];
        let costCalculationRows: TableRowDto[] = [];

        if (recommendedPriceCatalogTableRows.length > 0) {
          costCalculationFields =
            priceCatalogTable.childTable?.fields.filter(
              field => field.referenceId in COST_CALCULATION_TABLE_FIELDS
            ) ?? [];
          costCalculationRows = (priceCatalogTable.childTable?.rows ?? []).filter(
            row =>
              row.parentTableRowId &&
              recommendedPriceCatalogTableRows.map(r => r.id).includes(row.parentTableRowId)
          );
        } else if (projectTableRow) {
          costCalculationFields = projectTable.childTable?.fields ?? [];
          costCalculationRows = (projectTable.childTable?.rows ?? []).filter(
            row => row.parentTableRowId === projectTableRow.id
          );
        }

        if (costCalculationRows.length > 0) {
          const xmlWBSItemEstDetailsConverter = new XmlWBSItemEstDetailsConverter();
          const nonEstDetailsElements = (item.elements ?? []).filter(
            element => element.type === 'element' && element.name !== 'EstDetails'
          );
          const wbsItems = convertRowsToWBSItemEstDetails(
            costCalculationFields,
            costCalculationRows,
            null
          );

          return {
            ...item,
            elements: [
              ...nonEstDetailsElements,
              xmlWBSItemEstDetailsConverter.toXml({
                items: wbsItems
              })
            ]
          };
        }
        return item;
      });
    });
  }

  return projectXmlJson;
};

const convertRowsToWBSItemEstDetails = (
  fields: ExportTableField[],
  rows: TableRowDto[],
  parentRowId: string | null
): WBSItemEstDetailsItem[] => {
  const rowsMatchingParentId = rows.filter(row => row.parentRowId === parentRowId);

  if (rowsMatchingParentId.length === 0) {
    return [];
  }

  const referenceIdToFieldId = fields.reduce(
    (acc, field) => {
      if (field.referenceId && field.referenceId in COST_CALCULATION_TABLE_FIELDS) {
        acc[field.referenceId as CostCalculationTableField] = field.id;
      }
      return acc;
    },
    {} as Record<CostCalculationTableField, string>
  );

  return rowsMatchingParentId
    .map((row): WBSItemEstDetailsItem | undefined => {
      const type = row[referenceIdToFieldId['type']] as CostCalculationTypeTableFieldSelectOptionId;
      if (type === 'CoCDetail') {
        return {
          type: 'CoCDetail' as const,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          urValue: row[referenceIdToFieldId['costPerUnit']] as number | undefined,
          qFactorCoc: row[referenceIdToFieldId['qFactorCoc']] as number | undefined,
          cFactorCoc: row[referenceIdToFieldId['cFactorCoc']] as number | undefined,
          currency: row[referenceIdToFieldId['currency']] as string | undefined,
          key: row[referenceIdToFieldId['key']] as string | undefined,
          name: row[referenceIdToFieldId['name']] as string | undefined,
          identifyKey: row[referenceIdToFieldId['identifyKey']] as string | undefined,
          isDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'SubItem') {
        return {
          type: 'SubItem' as const,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          compressed: row[referenceIdToFieldId['compressed']] as boolean | undefined,
          estDetails: {
            items: convertRowsToWBSItemEstDetails(fields, rows, row.id)
          },
          sItemDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          sItemLSum: row[referenceIdToFieldId['sItemLSum']] as string | undefined,
          text: row[referenceIdToFieldId['name']] as string | undefined,
          unitOfMeasure: row[referenceIdToFieldId['unit']] as string | undefined,
          subItemNumber: row[referenceIdToFieldId['referenceNumber']] as string | undefined,
          sItemLSumAbs: row[referenceIdToFieldId['sItemLSumAbs']] as string | undefined,
          sItemNo: row[referenceIdToFieldId['sItemNo']] as string | undefined,
          sItemReserve: row[referenceIdToFieldId['sItemReserve']] as string | undefined,
          spPhase: row[referenceIdToFieldId['spPhase']] as string | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'EstTextElement') {
        return {
          type: 'EstTextElement' as const,
          text: row[referenceIdToFieldId['name']] as string | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'CommodityDetail') {
        return {
          type: 'CommodityDetail' as const,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          currency: row[referenceIdToFieldId['currency']] as string | undefined,
          identifyKey: row[referenceIdToFieldId['identifyKey']] as string | undefined,
          isDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          nameCommodity: row[referenceIdToFieldId['key']] as string | undefined,
          descrCommodity: row[referenceIdToFieldId['name']] as string | undefined,
          urValue: row[referenceIdToFieldId['costPerUnit']] as number | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          estDetails: {
            items: convertRowsToWBSItemEstDetails(fields, rows, row.id)
          },
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      }

      return undefined;
    })
    .filter(removeNullish);
};
