import { i18n } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import {
  CreateProjectDashboard,
  PROJECT_TYPE_TO_DASHBOARD_ITEM_REFERENCE_ID,
  ProjectDashboardItemContent,
  ProjectDashboardItemType,
  ProjectType
} from '../../types';

export const projectTypeToDashboardConfig: Record<ProjectType, CreateProjectDashboard> = {
  CONSTRUCTION_I_TWO: {
    groups: [
      {
        items: [
          {
            referenceId:
              PROJECT_TYPE_TO_DASHBOARD_ITEM_REFERENCE_ID.CONSTRUCTION_I_TWO.projectInformation,
            isHidden: false,
            canUserEdit: false,
            canUserRegenerate: true,
            content: {
              type: 'RICH_TEXT',
              text: null,
              title: i18n._(msg({ id: '📋 Project Information' })),
              emptyText: i18n._(msg({ id: 'Project information could not be found' }))
            }
          }
        ]
      },
      {
        items: [
          {
            referenceId:
              PROJECT_TYPE_TO_DASHBOARD_ITEM_REFERENCE_ID.CONSTRUCTION_I_TWO.projectSummary,
            isHidden: false,
            canUserEdit: false,
            canUserRegenerate: true,
            content: {
              type: 'RICH_TEXT',
              text: null,
              title: i18n._(msg({ id: '🛠️ Summary' })),
              emptyText: i18n._(msg({ id: 'Summary could not be created' }))
            }
          }
        ]
      },
      {
        items: [
          {
            referenceId:
              PROJECT_TYPE_TO_DASHBOARD_ITEM_REFERENCE_ID.CONSTRUCTION_I_TWO.projectFiles,
            isHidden: false,
            canUserEdit: false,
            canUserRegenerate: false,
            content: {
              type: 'HORIZONTAL_PIECE_OF_INFORMATION_LIST',
              piecesOfInformation: null,
              emptyText: i18n._(msg({ id: 'No files found' })),
              title: i18n._(msg({ id: '📄 Project Files' }))
            }
          }
        ]
      },
      {
        items: [
          {
            referenceId:
              PROJECT_TYPE_TO_DASHBOARD_ITEM_REFERENCE_ID.CONSTRUCTION_I_TWO.similarProjects,
            isHidden: false,
            canUserEdit: false,
            canUserRegenerate: true,
            content: {
              type: 'HORIZONTAL_PIECE_OF_INFORMATION_LIST',
              piecesOfInformation: null,
              emptyText: i18n._(msg({ id: 'No similar projects found' })),
              title: i18n._(msg({ id: '🏗️ Similar Projects' }))
            }
          }
        ]
      }
    ]
  }
};

export const getTypedProjectDashboardItemContent = <
  TProjectDashboardItemType extends ProjectDashboardItemType,
  TContent extends ProjectDashboardItemContent = ProjectDashboardItemContent
>(
  content: TContent
) => {
  return content as Extract<TContent, { type: TProjectDashboardItemType }>;
};
