import { z } from 'zod';

export const proposedChangeDataTypeSchema = z.enum(['TABLE_ROW']);
export type ProposedChangeDataType = z.infer<typeof proposedChangeDataTypeSchema>;

export const proposedChangeTypeSchema = z.enum(['INSERT', 'UPDATE', 'DELETE']);
export type ProposedChangeType = z.infer<typeof proposedChangeTypeSchema>;

export const proposedChangeStatusSchema = z.enum(['PENDING', 'APPLIED', 'DECLINED']);
export type ProposedChangeStatus = z.infer<typeof proposedChangeStatusSchema>;
