'use client';

import { Box, Center, Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import { CheckIcon, CircleXIcon, FileIcon, XIcon } from '@company/ui/icons';
import React, { ReactElement } from 'react';
import { FileUploadStatus, UploadingFile } from './input/types';
import { IconButton, ProgressCircleRing, ProgressCircleRoot } from '@company/ui/components';

export interface FileListProps {
  files: UploadingFile[];
  onRemoveFile: (fileId: string) => void;
  isReadOnly?: boolean;
}

export const FileList = ({ files, onRemoveFile, isReadOnly = false }: FileListProps) => {
  return (
    <Stack gap={0.5}>
      {files.map((file, index) => (
        <FileUploadItem
          key={index}
          file={file}
          removeFile={() => onRemoveFile(file.id)}
          isReadOnly={isReadOnly}
        />
      ))}
    </Stack>
  );
};

interface FileUploadItemProps {
  file: UploadingFile;
  removeFile: () => void;
  isReadOnly?: boolean;
}

const fileStatusToElement: Record<FileUploadStatus, (file: UploadingFile) => ReactElement> = {
  UPLOADING: file => (
    <ProgressCircleRoot size={'xs'} value={file.progress} colorPalette={'primary'}>
      <ProgressCircleRing cap="round" />
    </ProgressCircleRoot>
  ),
  UPLOADED: file => <CheckIcon color={'green.500'} fontSize={20} />,
  ERROR: file => <CircleXIcon color={'red.500'} fontSize={20} />
};

const FileUploadItem: React.FC<FileUploadItemProps> = ({
  file,
  removeFile,
  isReadOnly = false
}) => {
  const fileStatusElement = React.useMemo(() => fileStatusToElement[file.status](file), [file]);

  return (
    <Box w={'full'}>
      <Flex
        align={'center'}
        pl={3}
        pr={3}
        py={0.5}
        borderWidth={1}
        borderColor={'gray.200'}
        borderRadius="md"
        h={'38px'}
        w={'full'}
        bgColor={'white'}
        gap={1}
      >
        <Center w={'20px'}>
          {isReadOnly ? <FileIcon color={'gray.600'} /> : fileStatusElement}
        </Center>
        <Text fontSize={'sm'} pl={1} truncate maxW={'300px'}>
          {file.name}
        </Text>
        {!isReadOnly && (
          <>
            <Spacer />
            <IconButton aria-label="Remove file" size={'xs'} variant={'ghost'} onClick={removeFile}>
              <XIcon />
            </IconButton>
          </>
        )}
      </Flex>
    </Box>
  );
};
