import { Box, Link } from '@chakra-ui/react';
import { isMention, parseMention, splitTextByMentions } from '@company/common/lib';
import NextLink from 'next/link';
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { ExternalLink } from '../link';
import { Prose } from '../ui/prose';

interface ReactMarkdownProps {
  markdown: string;
  styling?: {
    paragraphsHaveMargin?: boolean;
  };
}

const Mention = ({ children, className }: { children: React.ReactNode; className: string }) => (
  <Box as="span" className={className}>
    {children}
  </Box>
);

export const ReactMarkdown = React.memo(({ markdown, styling }: ReactMarkdownProps) => {
  return (
    <Prose w={'full'}>
      <Markdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeRaw]}
        components={{
          p({ node, children, style }) {
            return (
              <p
                style={{
                  ...style,
                  marginBottom: styling?.paragraphsHaveMargin ? '0.5em' : (style?.marginBottom ?? 0)
                }}
              >
                <TextWithMentions>{children as React.ReactNode}</TextWithMentions>
              </p>
            );
          },
          li({ node, children, style }) {
            return (
              <li style={style}>
                <TextWithMentions>{children as React.ReactNode}</TextWithMentions>
              </li>
            );
          },
          span({ node, children, style }) {
            return (
              <span style={style}>
                <TextWithMentions>{children as React.ReactNode}</TextWithMentions>
              </span>
            );
          },
          b({ node, children, style }) {
            return (
              <b style={style}>
                <TextWithMentions>{children as React.ReactNode}</TextWithMentions>
              </b>
            );
          },
          i({ node, children, style }) {
            return (
              <i style={style}>
                <TextWithMentions>{children as React.ReactNode}</TextWithMentions>
              </i>
            );
          },
          a({ node, children, href, style }) {
            const isInternal = href?.startsWith('/');

            if (isInternal) {
              return (
                <Link as={NextLink} href={href} style={style}>
                  {children as React.ReactNode}
                </Link>
              );
            }
            if (href) {
              return (
                <ExternalLink href={href} style={style}>
                  {children as React.ReactNode}
                </ExternalLink>
              );
            }
            return <>{children as React.ReactNode}</>;
          }
        }}
      >
        {markdown}
      </Markdown>
    </Prose>
  );
});

const TextWithMentions: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const processNode = (node: React.ReactNode): React.ReactNode => {
    if (typeof node === 'string') {
      const segments = splitTextByMentions(node);
      return segments.map((segment, index) => {
        if (isMention(segment)) {
          const mention = parseMention(segment)!;
          return (
            <Mention
              key={index}
              className={`mention ${mention.type === 'task' ? 'task-mention' : mention.type === 'conversationMember' ? 'conversation-member-mention' : 'piece-of-information-mention'}`}
            >
              {mention.prefix}
              {mention.label}
            </Mention>
          );
        }
        return segment;
      });
    }
    return node;
  };

  return <>{React.Children.map(children, processNode)}</>;
};
