import { z } from 'zod';
import { tableDataTypeSchema } from '../table';
import { projectAdditionalInformationSchema } from '../project/additional-information';
import { projectTypeSchema } from '../project/type';

export const pieceOfInformationTypeSchema = z.enum(['TABLE', 'FILE', 'PROJECT', 'CONVERSATION']);
export const pieceOfInformationDtoSchema = z
  .object({
    id: z.string(),
    type: pieceOfInformationTypeSchema,
    itemId: z.string(),
    name: z.string(),
    createdOn: z.date()
  })
  .and(
    z.discriminatedUnion('type', [
      z.object({
        type: z.literal('TABLE'),
        table: z.object({
          id: z.string(),
          name: z.string(),
          dataType: tableDataTypeSchema.nullable(),
          pieceOfInformationId: z.string(),
          defaultViewId: z.string()
        })
      }),
      z.object({
        type: z.literal('FILE'),
        file: z.object({
          id: z.string(),
          name: z.string(),
          extension: z.string(),
          pieceOfInformationId: z.string()
        })
      }),
      z.object({
        type: z.literal('PROJECT'),
        project: z.object({
          id: z.string(),
          name: z.string(),
          pieceOfInformationId: z.string(),
          description: z.string().nullable(),
          type: projectTypeSchema.nullable(),
          additionalInformation: projectAdditionalInformationSchema.nullable()
        })
      }),
      z.object({
        type: z.literal('CONVERSATION'),
        conversation: z.object({
          id: z.string(),
          name: z.string(),
          projectId: z.string().nullish(),
          pieceOfInformationId: z.string()
        })
      })
    ])
  );

export type PieceOfInformationDto = z.infer<typeof pieceOfInformationDtoSchema>;
export type TablePieceOfInformationDto = Extract<PieceOfInformationDto, { type: 'TABLE' }>['table'];
export type FilePieceOfInformationDto = Extract<PieceOfInformationDto, { type: 'FILE' }>['file'];
export type ProjectPieceOfInformationDto = Extract<
  PieceOfInformationDto,
  { type: 'PROJECT' }
>['project'];
export type ConversationPieceOfInformationDto = Extract<
  PieceOfInformationDto,
  { type: 'CONVERSATION' }
>['conversation'];
export type PieceOfInformationType = z.infer<typeof pieceOfInformationTypeSchema>;
