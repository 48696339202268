import { ExportTable, TableRowDto, ToUploadFile } from '@company/common/types';
import { json2xml, xml2json } from 'xml-js';
import { rowValueToString } from '../../table';
import { findXmlElementByName, XmlDocument, XmlNode } from '../xml-converter';
import { getDefaultGaebXml } from './default-gaeb-xml';
import {
  FieldReferenceIdToFieldId,
  getChildRowsOfRow,
  getFieldReferenceIdToFieldId,
  removeEndingDot
} from './utils';

export const exportTableAsGaebFile = ({
  table,
  ...rest
}: {
  table: ExportTable;
} & (
  | {
      gaebXml: string;
    }
  | {
      projectName: string;
      projectDescription: string;
    }
)): ToUploadFile => {
  const gaebXmlString =
    'gaebXml' in rest ? rest.gaebXml : getDefaultGaebXml(rest.projectName, rest.projectDescription);
  let gaebXmlJson: XmlDocument = JSON.parse(
    xml2json(gaebXmlString, {
      compact: false,
      spaces: 2
    })
  );

  updateGaebXml(gaebXmlJson, table);

  return {
    content: json2xml(JSON.stringify(gaebXmlJson), { compact: false, spaces: 2 }),
    name: `${table.name}.x83`,
    extension: 'x83'
  };
};

const updateGaebXml = (gaebXmlJson: XmlDocument, table: ExportTable): XmlDocument => {
  const gaebElement = findXmlElementByName(gaebXmlJson.elements, 'GAEB');
  const awardElement = findXmlElementByName(gaebElement?.elements, 'Award');
  const boqElement = findXmlElementByName(awardElement?.elements, 'BoQ');

  const fieldReferenceIdToFieldId = getFieldReferenceIdToFieldId({
    fields: table.fields.map(f => ({ id: f.id, referenceId: f.referenceId }))
  });

  const rows = table.rows;

  if (boqElement) {
    boqElement.elements = [
      ...(boqElement.elements?.filter(e => e.type !== 'element' || e.name !== 'BoQBody') ?? []),
      createBoqBodyElement({
        childRows: rows.filter(r => r.parentRowId === null),
        allRows: rows,
        fieldReferenceIdToFieldId
      })
    ];
  }

  return gaebXmlJson;
};

const createBoqBodyElement = ({
  childRows,
  allRows,
  fieldReferenceIdToFieldId
}: {
  childRows: TableRowDto[];
  allRows: TableRowDto[];
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
}): XmlNode => {
  const categoryRows = childRows.filter(row => isCategoryRow(row, fieldReferenceIdToFieldId));
  const itemRows = childRows.filter(row => !isCategoryRow(row, fieldReferenceIdToFieldId));

  const elements: XmlNode[] =
    categoryRows.length > 0
      ? categoryRows.map(row => {
          const referenceNumber = rowValueToString(row[fieldReferenceIdToFieldId.referenceNumber]);
          const nextChildRows = getChildRowsOfRow({ row, rows: allRows });
          return createBoqCtgyElement({
            categoryRow: row,
            childRows: nextChildRows,
            allRows,
            fieldReferenceIdToFieldId,
            refNumberPart: referenceNumber
          });
        })
      : [createItemListElement({ childRows: itemRows, fieldReferenceIdToFieldId })];

  return {
    type: 'element',
    name: 'BoQBody',
    elements
  };
};

const isCategoryRow = (
  row: TableRowDto,
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId
): boolean => row[fieldReferenceIdToFieldId.unit] === '' || !row[fieldReferenceIdToFieldId.unit];

const createBoqCtgyElement = ({
  categoryRow,
  childRows,
  allRows,
  fieldReferenceIdToFieldId,
  refNumberPart
}: {
  categoryRow: TableRowDto;
  childRows: TableRowDto[];
  allRows: TableRowDto[];
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
  refNumberPart: string;
}): XmlNode => {
  const categoryName = removeEndingDot(
    rowValueToString(categoryRow[fieldReferenceIdToFieldId.text])
  );

  return {
    type: 'element',
    name: 'BoQCtgy',
    attributes: {
      ID: categoryRow.id,
      RNoPart: refNumberPart
    },
    elements: [
      {
        type: 'element',
        name: 'LblTx',
        elements: [
          {
            type: 'element',
            name: 'p',
            elements: [
              {
                type: 'element',
                name: 'span',
                elements: [{ type: 'text', text: categoryName }]
              }
            ]
          }
        ]
      },
      createBoqBodyElement({
        childRows,
        allRows,
        fieldReferenceIdToFieldId
      })
    ]
  };
};

const createItemListElement = ({
  childRows,
  fieldReferenceIdToFieldId
}: {
  childRows: TableRowDto[];
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
}): XmlNode => {
  return {
    type: 'element',
    name: 'Itemlist',
    elements: childRows.map((row, index) => {
      const referenceNumber = removeEndingDot(
        rowValueToString(row[fieldReferenceIdToFieldId.referenceNumber])
      );
      return createItemElement({
        itemRow: row,
        refNumberPart: referenceNumber,
        fieldReferenceIdToFieldId
      });
    })
  };
};

const createItemElement = ({
  itemRow,
  refNumberPart,
  fieldReferenceIdToFieldId
}: {
  itemRow: TableRowDto;
  refNumberPart: string;
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
}): XmlNode => {
  const unit = rowValueToString(itemRow[fieldReferenceIdToFieldId.unit]);
  const name = rowValueToString(itemRow[fieldReferenceIdToFieldId.text]);
  const description = rowValueToString(itemRow[fieldReferenceIdToFieldId.description]);
  const descriptionLines = description.split('\n').filter(line => line.trim() !== '');

  return {
    type: 'element',
    name: 'Item',
    attributes: {
      ID: itemRow.id,
      RNoPart: refNumberPart
    },
    elements: [
      {
        type: 'element',
        name: 'LumpSumItem',
        elements: [
          {
            type: 'text',
            text: 'Yes'
          }
        ]
      },
      {
        type: 'element',
        name: 'UPBkdn',
        elements: [
          {
            type: 'text',
            text: 'Yes'
          }
        ]
      },
      {
        type: 'element',
        name: 'Qty',
        elements: [
          {
            type: 'text',
            text: '1.000'
          }
        ]
      },
      {
        type: 'element',
        name: 'QU',
        elements: [
          {
            type: 'text',
            text: unit
          }
        ]
      },
      {
        type: 'element',
        name: 'Description',
        elements: [
          {
            type: 'element',
            name: 'CompleteText',
            elements: [
              {
                type: 'element',
                name: 'DetailTxt',
                elements: [
                  {
                    type: 'element',
                    name: 'Text',
                    elements: [
                      {
                        type: 'element',
                        name: 'p',
                        attributes: {
                          style: 'text-align:left;margin-top:0pt;margin-bottom:0pt;'
                        },
                        elements: descriptionLines.flatMap((line, index) => {
                          const descriptionElement = {
                            type: 'element' as const,
                            name: 'span',
                            attributes: {
                              style: 'font-family:Arial;font-size:10pt;Color:rgb(0,0,0);'
                            },
                            elements: [{ type: 'text' as const, text: line }]
                          };

                          if (index < descriptionLines.length - 1) {
                            return [descriptionElement, { type: 'element' as const, name: 'br' }];
                          }

                          return [descriptionElement];
                        })
                      }
                    ]
                  }
                ]
              },
              {
                type: 'element',
                name: 'OutlineText',
                elements: [
                  {
                    type: 'element',
                    name: 'OutlTxt',
                    elements: [
                      {
                        type: 'element',
                        name: 'TextOutlTxt',
                        elements: [
                          {
                            type: 'element',
                            name: 'p',
                            attributes: {
                              style: 'text-align:left;margin-top:0pt;margin-bottom:0pt;'
                            },
                            elements: [
                              {
                                type: 'element',
                                name: 'span',
                                elements: [{ type: 'text', text: name }]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };
};
