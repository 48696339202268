import { typeidUnboxed } from 'typeid-js';
import { UpsertConversationNode, UpsertConversationNodeElement } from '../../types';

export const conversationNodeId = () => typeidUnboxed('conversationnode');
export const conversationMemberId = () => typeidUnboxed('conversationmember');
export const conversationNodeElementId = () => typeidUnboxed('conversationnodeelmt');

export const createUpsertConversationNode = ({
  id,
  elements,
  userId,
  previousNodeId
}: {
  id?: string;
  elements: UpsertConversationNodeElement[];
  userId: string;
  previousNodeId: string | null;
}): UpsertConversationNode => {
  return {
    id: id ?? conversationNodeId(),
    previousNodeId,
    createdOn: new Date(),
    sender: {
      userId
    },
    elements
  };
};

export const sortConversationNodes = <
  TNode extends {
    id: string;
    previousNodeId: string | null;
  }
>(
  nodes: TNode[]
): TNode[] => {
  if (nodes.length === 0) {
    return [];
  }

  const nodeIdToNextNode = nodes.reduce(
    (acc, node) => {
      if (node.previousNodeId !== null) {
        acc[node.previousNodeId] = node;
      }
      return acc;
    },
    {} as Record<string, TNode>
  );

  // Find the first node (one with no previous node or pointing to non-existent node)
  const existingNodeIds = new Set(nodes.map(node => node.id));
  const firstNode = nodes.find(
    node => node.previousNodeId === null || !existingNodeIds.has(node.previousNodeId)
  );

  if (!firstNode) {
    throw new Error('Could not find first node - there might be a cycle in the chain');
  }

  const sortedNodes: TNode[] = [];
  let currentNode: TNode | null = firstNode;
  const visitedNodes = new Set<string>();

  // Follow the chain of nodes
  while (currentNode !== null) {
    // Check for cycles
    if (visitedNodes.has(currentNode.id)) {
      throw new Error('Detected cycle in node chain');
    }

    sortedNodes.push(currentNode);
    visitedNodes.add(currentNode.id);

    currentNode = nodeIdToNextNode[currentNode.id] || null;
  }

  return sortedNodes;
};

export const getLastNodeId = <
  TNode extends {
    id: string;
  }
>(
  nodes: TNode[]
) => {
  if (nodes.length === 0) {
    return null;
  }

  return nodes[nodes.length - 1]!.id;
};
