import { z } from 'zod';

// Schema for a single table sort
export const tableSortItemSchema = z.object({
  fieldId: z.string(),
  direction: z.enum(['ASC', 'DESC'])
});
export type TableSortItem = z.infer<typeof tableSortItemSchema>;

// Schema for a list of table sorts
export const tableSortListSchema = z.array(tableSortItemSchema);
export type TableSortList = z.infer<typeof tableSortListSchema>;
