'use client';

import { ListCollection } from '@ark-ui/react';
import {
  Center,
  ComboboxContent,
  ComboboxContentProps,
  ComboboxControl,
  ComboboxInput,
  ComboboxItem,
  ComboboxItemGroup,
  ComboboxList,
  ComboboxPositioner,
  ComboboxRoot,
  createListCollection,
  Flex,
  Spinner,
  Text
} from '@company/ui/components';
import React from 'react';
import { InputProps } from '../combobox/namespace';

interface SearchItem {
  id: string;
}

type SearchSize = 'sm' | 'md' | 'lg';

interface SearchProps<TSearchItem extends SearchItem> {
  inputValue: string;
  setInputValue: (value: string) => void;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  items: TSearchItem[];
  placeholder: string;
  renderItem: (item: TSearchItem) => React.ReactNode;
  isLoading?: boolean;
  onValueChange: (item: TSearchItem | null) => void;
  isOverlay?: boolean;
  size?: SearchSize;
  contentProps?: ComboboxContentProps;
  inputProps?: InputProps;
  debounceTime?: number;
  texts: {
    noResults: string;
    loading: string;
  };
}

export const Search = <TSearchItem extends SearchItem>({
  inputValue,
  setInputValue,
  isOpen,
  setIsOpen,
  items,
  renderItem,
  isLoading,
  onValueChange: onValueChangeHandler,
  inputProps,
  contentProps,
  isOverlay = false,
  placeholder,
  size = 'md',
  texts
}: SearchProps<TSearchItem>) => {
  const collection = React.useMemo(() => {
    return createListCollection<TSearchItem & { value: string }>({
      items: items.map(item => ({
        ...item,
        value: item.id
      }))
    });
  }, [items]);

  const onInputValueChange = ({ inputValue }: { inputValue: string }) => {
    setInputValue(inputValue);
  };

  return (
    <ComboboxRoot
      open={isOpen}
      onOpenChange={({ open }) => setIsOpen(open)}
      collection={collection}
      onValueChange={({ items }: { items: unknown[] }) => {
        onValueChangeHandler((items[0] ?? null) as TSearchItem | null);
        setIsOpen(false);
      }}
      onInputValueChange={onInputValueChange}
      placeholder={placeholder}
      size={size}
      inputBehavior="autohighlight"
      openOnClick={true}
      selectionBehavior="preserve"
    >
      <ComboboxControl size={size}>
        <ComboboxInput {...inputProps} size={size} />
      </ComboboxControl>
      {isOverlay ? (
        <ComboboxPositioner size={size}>
          <Content
            collection={collection}
            inputValue={inputValue}
            renderItem={renderItem}
            size={size}
            texts={texts}
            isLoading={isLoading}
            contentProps={contentProps}
          />
        </ComboboxPositioner>
      ) : (
        <Content
          collection={collection}
          inputValue={inputValue}
          renderItem={renderItem}
          size={size}
          texts={texts}
          contentProps={contentProps}
          isLoading={isLoading}
        />
      )}
    </ComboboxRoot>
  );
};

const Content = <TSearchItem extends SearchItem>({
  collection,
  inputValue,
  renderItem,
  isLoading,
  size,
  contentProps,
  texts
}: {
  collection: ListCollection<TSearchItem & { value: string }>;
  inputValue: string;
  renderItem: (item: TSearchItem) => React.ReactNode;
  isLoading?: boolean;
  size: SearchSize;
  contentProps?: ComboboxContentProps;
  texts: {
    noResults: string;
    loading: string;
  };
}) => {
  return (
    <ComboboxContent {...contentProps} overflow="auto" overscrollBehavior="contain" size={size}>
      <ComboboxList size={size}>
        {collection.items.length === 0 && (
          <Center p="3">
            {isLoading ? (
              <Flex align="center" gap="2">
                <Spinner size="sm" />
                <Text color="fg.muted" textStyle={size} fontSize={'sm'}>
                  {texts.loading}
                </Text>
              </Flex>
            ) : (
              <Text color="fg.muted" textStyle={size} fontSize={'sm'}>
                {texts.noResults} "<Text as="strong">{inputValue}</Text>"
              </Text>
            )}
          </Center>
        )}
        {collection.items.length > 0 && (
          <ComboboxItemGroup>
            {collection.items.map(item => (
              <ComboboxItem key={item.id} item={item} height="auto" size={size}>
                {renderItem(item)}
              </ComboboxItem>
            ))}
          </ComboboxItemGroup>
        )}
      </ComboboxList>
    </ComboboxContent>
  );
};
