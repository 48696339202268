import { Icon as ChakraIcon, IconProps as ChakraIconProps } from '@chakra-ui/react';
import * as LucideIcons from 'lucide-react';
import { ForwardRefExoticComponent, RefAttributes } from 'react';

// Helper function to create icon components
const createIcon =
  (
    LucideIcon: ForwardRefExoticComponent<
      Omit<LucideIcons.LucideProps, 'ref'> & RefAttributes<SVGSVGElement>
    >
  ) =>
  (props: ChakraIconProps) => (
    <ChakraIcon {...props}>
      <LucideIcon />
    </ChakraIcon>
  );

// Export icons
export const ActivityIcon = createIcon(LucideIcons.ActivityIcon);
export const AlignLeftIcon = createIcon(LucideIcons.AlignLeftIcon);
export const AlignRightIcon = createIcon(LucideIcons.AlignRightIcon);
export const ArchiveIcon = createIcon(LucideIcons.ArchiveIcon);
export const ArrowDownFromLineIcon = createIcon(LucideIcons.ArrowDownFromLineIcon);
export const ArrowDownIcon = createIcon(LucideIcons.ArrowDownIcon);
export const ArrowDownRightIcon = createIcon(LucideIcons.ArrowDownRightIcon);
export const ArrowLeftIcon = createIcon(LucideIcons.ArrowLeftIcon);
export const ArrowRightIcon = createIcon(LucideIcons.ArrowRightIcon);
export const ArrowUpFromLineIcon = createIcon(LucideIcons.ArrowUpFromLineIcon);
export const ArrowUpIcon = createIcon(LucideIcons.ArrowUpIcon);
export const AsteriskIcon = createIcon(LucideIcons.AsteriskIcon);
export const AtSignIcon = createIcon(LucideIcons.AtSignIcon);
export const BanIcon = createIcon(LucideIcons.BanIcon);
export const BarChart2Icon = createIcon(LucideIcons.BarChart2Icon);
export const BaselineIcon = createIcon(LucideIcons.BaselineIcon);
export const BetweenVerticalStartIcon = createIcon(LucideIcons.BetweenVerticalStartIcon);
export const BinaryIcon = createIcon(LucideIcons.BinaryIcon);
export const BookAIcon = createIcon(LucideIcons.BookAIcon);
export const BookOpenIcon = createIcon(LucideIcons.BookOpenIcon);
export const BookOpenTextIcon = createIcon(LucideIcons.BookOpenTextIcon);
export const BotMessageSquareIcon = createIcon(LucideIcons.BotMessageSquareIcon);
export const BoxIcon = createIcon(LucideIcons.BoxIcon);
export const BriefcaseIcon = createIcon(LucideIcons.BriefcaseIcon);
export const Building2Icon = createIcon(LucideIcons.Building2Icon);
export const BuildingIcon = createIcon(LucideIcons.BuildingIcon);
export const CalendarDaysIcon = createIcon(LucideIcons.CalendarDaysIcon);
export const CalendarIcon = createIcon(LucideIcons.CalendarIcon);
export const CircleAlertIcon = createIcon(LucideIcons.CircleAlertIcon);
export const CheckCircleIcon = createIcon(LucideIcons.CheckCircleIcon);
export const ClipboardListIcon = createIcon(LucideIcons.ClipboardListIcon);
export const CheckIcon = createIcon(LucideIcons.CheckIcon);
export const ChevronDownIcon = createIcon(LucideIcons.ChevronDownIcon);
export const ChevronLeftIcon = createIcon(LucideIcons.ChevronLeftIcon);
export const ChevronRightIcon = createIcon(LucideIcons.ChevronRightIcon);
export const ChevronsRightIcon = createIcon(LucideIcons.ChevronsRightIcon);
export const ChevronUpIcon = createIcon(LucideIcons.ChevronUpIcon);
export const CircleChevronDownIcon = createIcon(LucideIcons.CircleChevronDownIcon);
export const CircleDotIcon = createIcon(LucideIcons.CircleDotIcon);
export const CircleHelpIcon = createIcon(LucideIcons.CircleHelpIcon);
export const CircleIcon = createIcon(LucideIcons.CircleIcon);
export const CirclePauseIcon = createIcon(LucideIcons.CirclePauseIcon);
export const CirclePlusIcon = createIcon(LucideIcons.CirclePlusIcon);
export const CircleXIcon = createIcon(LucideIcons.CircleXIcon);
export const ClipboardIcon = createIcon(LucideIcons.ClipboardIcon);
export const ClockIcon = createIcon(LucideIcons.ClockIcon);
export const Columns2Icon = createIcon(LucideIcons.Columns2Icon);
export const CopyIcon = createIcon(LucideIcons.CopyIcon);
export const CopyPlusIcon = createIcon(LucideIcons.CopyPlusIcon);
export const CornerDownRightIcon = createIcon(LucideIcons.CornerDownRightIcon);
export const DatabaseBackupIcon = createIcon(LucideIcons.DatabaseBackupIcon);
export const DatabaseIcon = createIcon(LucideIcons.DatabaseIcon);
export const DiffIcon = createIcon(LucideIcons.DiffIcon);
export const DollarSignIcon = createIcon(LucideIcons.DollarSignIcon);
export const DownloadIcon = createIcon(LucideIcons.DownloadIcon);
export const EarthIcon = createIcon(LucideIcons.EarthIcon);
export const EllipsisIcon = createIcon(LucideIcons.EllipsisIcon);
export const EllipsisVerticalIcon = createIcon(LucideIcons.EllipsisVerticalIcon);
export const ExpandIcon = createIcon(LucideIcons.ExpandIcon);
export const ExternalLinkIcon = createIcon(LucideIcons.ExternalLinkIcon);
export const EyeIcon = createIcon(LucideIcons.EyeIcon);
export const EyeOffIcon = createIcon(LucideIcons.EyeOffIcon);
export const FileBadge2Icon = createIcon(LucideIcons.FileBadge2Icon);
export const FileBadgeIcon = createIcon(LucideIcons.FileBadgeIcon);
export const FileDiffIcon = createIcon(LucideIcons.FileDiffIcon);
export const FileDownIcon = createIcon(LucideIcons.FileDownIcon);
export const FileIcon = createIcon(LucideIcons.FileIcon);
export const FilePlusIcon = createIcon(LucideIcons.FilePlusIcon);
export const FileQuestionIcon = createIcon(LucideIcons.FileQuestionIcon);
export const FileSpreadsheetIcon = createIcon(LucideIcons.FileSpreadsheetIcon);
export const FilesIcon = createIcon(LucideIcons.FilesIcon);
export const FilterIcon = createIcon(LucideIcons.FilterIcon);
export const FilterXIcon = createIcon(LucideIcons.FilterXIcon);
export const FlagIcon = createIcon(LucideIcons.FlagIcon);
export const FlagTriangleLeftIcon = createIcon(LucideIcons.FlagTriangleLeftIcon);
export const Flower2Icon = createIcon(LucideIcons.Flower2Icon);
export const FolderIcon = createIcon(LucideIcons.FolderIcon);
export const FootprintsIcon = createIcon(LucideIcons.FootprintsIcon);
export const GitCompareArrowsIcon = createIcon(LucideIcons.GitCompareArrowsIcon);
export const GlobeIcon = createIcon(LucideIcons.GlobeIcon);
export const GripVerticalIcon = createIcon(LucideIcons.GripVerticalIcon);
export const GrabIcon = createIcon(LucideIcons.GrabIcon);
export const HashIcon = createIcon(LucideIcons.HashIcon);
export const HeadingIcon = createIcon(LucideIcons.HeadingIcon);
export const HeartIcon = createIcon(LucideIcons.HeartIcon);
export const HistoryIcon = createIcon(LucideIcons.HistoryIcon);
export const HomeIcon = createIcon(LucideIcons.HomeIcon);
export const HospitalIcon = createIcon(LucideIcons.HospitalIcon);
export const InfoIcon = createIcon(LucideIcons.InfoIcon);
export const LaptopMinimalIcon = createIcon(LucideIcons.LaptopMinimalIcon);
export const LayersIcon = createIcon(LucideIcons.LayersIcon);
export const LayoutDashboardIcon = createIcon(LucideIcons.LayoutDashboardIcon);
export const LayoutGridIcon = createIcon(LucideIcons.LayoutGridIcon);
export const LayoutIcon = createIcon(LucideIcons.LayoutIcon);
export const LetterTextIcon = createIcon(LucideIcons.LetterTextIcon);
export const LibraryIcon = createIcon(LucideIcons.LibraryIcon);
export const Link2Icon = createIcon(LucideIcons.Link2Icon);
export const LinkIcon = createIcon(LucideIcons.LinkIcon);
export const ListChecksIcon = createIcon(LucideIcons.ListChecksIcon);
export const ListIcon = createIcon(LucideIcons.ListIcon);
export const ListTodoIcon = createIcon(LucideIcons.ListTodoIcon);
export const LockIcon = createIcon(LucideIcons.LockIcon);
export const LogOutIcon = createIcon(LucideIcons.LogOutIcon);
export const MailIcon = createIcon(LucideIcons.MailIcon);
export const MapIcon = createIcon(LucideIcons.MapIcon);
export const MapPinIcon = createIcon(LucideIcons.MapPinIcon);
export const Maximize2Icon = createIcon(LucideIcons.Maximize2Icon);
export const Minimize2Icon = createIcon(LucideIcons.Minimize2Icon);
export const MenuIcon = createIcon(LucideIcons.MenuIcon);
export const MessageCircleIcon = createIcon(LucideIcons.MessageCircleIcon);
export const MessageCircleQuestionIcon = createIcon(LucideIcons.MessageCircleQuestionIcon);
export const MinusIcon = createIcon(LucideIcons.MinusIcon);
export const NotebookTabsIcon = createIcon(LucideIcons.NotebookTabsIcon);
export const NotepadTextDashedIcon = createIcon(LucideIcons.NotepadTextDashedIcon);
export const PanelLeftCloseIcon = createIcon(LucideIcons.PanelLeftCloseIcon);
export const PanelLeftIcon = createIcon(LucideIcons.PanelLeftIcon);
export const PanelLeftOpenIcon = createIcon(LucideIcons.PanelLeftOpenIcon);
export const PaperclipIcon = createIcon(LucideIcons.PaperclipIcon);
export const PauseIcon = createIcon(LucideIcons.PauseIcon);
export const PencilIcon = createIcon(LucideIcons.PencilIcon);
export const PencilLineIcon = createIcon(LucideIcons.PencilLineIcon);
export const PhoneIcon = createIcon(LucideIcons.PhoneIcon);
export const PickaxeIcon = createIcon(LucideIcons.PickaxeIcon);
export const PieChartIcon = createIcon(LucideIcons.PieChartIcon);
export const PilcrowIcon = createIcon(LucideIcons.PilcrowIcon);
export const PillIcon = createIcon(LucideIcons.PillIcon);
export const PlayIcon = createIcon(LucideIcons.PlayIcon);
export const PlusIcon = createIcon(LucideIcons.PlusIcon);
export const ReceiptTextIcon = createIcon(LucideIcons.ReceiptTextIcon);
export const RefreshCcwIcon = createIcon(LucideIcons.RefreshCcwIcon);
export const RefreshCwIcon = createIcon(LucideIcons.RefreshCwIcon);
export const RotateCcwIcon = createIcon(LucideIcons.RotateCcwIcon);
export const Rows2Icon = createIcon(LucideIcons.Rows2Icon);
export const ScaleIcon = createIcon(LucideIcons.ScaleIcon);
export const SearchIcon = createIcon(LucideIcons.SearchIcon);
export const SendIcon = createIcon(LucideIcons.SendIcon);
export const SettingsIcon = createIcon(LucideIcons.SettingsIcon);
export const ShapesIcon = createIcon(LucideIcons.ShapesIcon);
export const ShareIcon = createIcon(LucideIcons.ShareIcon);
export const SheetIcon = createIcon(LucideIcons.SheetIcon);
export const ShieldCheckIcon = createIcon(LucideIcons.ShieldCheckIcon);
export const ShieldIcon = createIcon(LucideIcons.ShieldIcon);
export const SparklesIcon = createIcon(LucideIcons.SparklesIcon);
export const SquareCheckIcon = createIcon(LucideIcons.SquareCheckIcon);
export const SquareEqualIcon = createIcon(LucideIcons.SquareEqualIcon);
export const SquareIcon = createIcon(LucideIcons.SquareIcon);
export const SquareMousePointer = createIcon(LucideIcons.SquareMousePointer);
export const StarHalfIcon = createIcon(LucideIcons.StarHalfIcon);
export const StarIcon = createIcon(LucideIcons.StarIcon);
export const TableIcon = createIcon(LucideIcons.TableIcon);
export const TabletsIcon = createIcon(LucideIcons.TabletsIcon);
export const TagIcon = createIcon(LucideIcons.TagIcon);
export const TargetIcon = createIcon(LucideIcons.TargetIcon);
export const TextIcon = createIcon(LucideIcons.TextIcon);
export const ThumbsUpIcon = createIcon(LucideIcons.ThumbsUpIcon);
export const TrashIcon = createIcon(LucideIcons.TrashIcon);
export const TypeIcon = createIcon(LucideIcons.TypeIcon);
export const UnplugIcon = createIcon(LucideIcons.UnplugIcon);
export const UploadIcon = createIcon(LucideIcons.UploadIcon);
export const User2Icon = createIcon(LucideIcons.User2Icon);
export const UserIcon = createIcon(LucideIcons.UserIcon);
export const UserMinusIcon = createIcon(LucideIcons.UserMinusIcon);
export const UserPlusIcon = createIcon(LucideIcons.UserPlusIcon);
export const UsersIcon = createIcon(LucideIcons.UsersIcon);
export const VariableIcon = createIcon(LucideIcons.VariableIcon);
export const ViewIcon = createIcon(LucideIcons.ViewIcon);
export const VideoIcon = createIcon(LucideIcons.VideoIcon);
export const WrapTextIcon = createIcon(LucideIcons.WrapTextIcon);
export const WandSparklesIcon = createIcon(LucideIcons.WandSparklesIcon);
export const XIcon = createIcon(LucideIcons.XIcon);
