'use client';

import { Box, Center, Stack } from '@chakra-ui/react';
import { FileUpIcon } from 'lucide-react';
import React from 'react';
import { useFileUpload } from '../../../hooks/file-upload';
import { FileList } from '../list';
import { FileUploadProps } from './types';

const bytesToMB = (bytes: number) => {
  return (bytes / 1024 / 1024).toFixed(0);
};

export const FileUploadDropzone: React.FC<FileUploadProps> = ({
  isReadOnly,
  maxSize = 104857600, // 100MB
  acceptedFileTypes,
  maxFiles,
  onChange,
  uploadFile,
  defaultFiles,
  fileHelpText,
  knowledgeLibrary,
  backgroundColor = 'white'
}) => {
  const { files, removeFile, getRootProps, getInputProps, isDragActive } = useFileUpload({
    maxSize,
    acceptedFileTypes,
    maxFiles,
    onChange,
    uploadFile,
    defaultFiles,
    knowledgeLibrary
  });

  if (isReadOnly) {
    return (
      <Box w={'full'} h={'full'}>
        <FileList files={files} onRemoveFile={removeFile} isReadOnly={isReadOnly} />
      </Box>
    );
  }

  return (
    <Box w={'full'} h={'full'}>
      <Box
        {...getRootProps()}
        w={'full'}
        h={'full'}
        mb={2}
        border="2px dashed"
        borderColor={isDragActive ? 'blue.500' : 'gray.200'}
        borderRadius="md"
        textAlign="center"
        cursor="pointer"
        fontSize={'sm'}
        bgColor={backgroundColor}
      >
        <input {...getInputProps()} />
        <Stack align={'center'} gap={2} h={'full'} w={'full'} p={6}>
          <Center h={10} w={10} bgColor={'gray.50'} rounded={'md'}>
            <FileUpIcon fontSize={18} />
          </Center>
          <Box>
            <Box>{fileHelpText.dragAndDrop}</Box>
            <Box fontSize={'xs'} color={'GrayText'}>
              {fileHelpText.fileSize} {bytesToMB(maxSize || 0)}MB
            </Box>
          </Box>
        </Stack>
      </Box>
      <FileList files={files} onRemoveFile={removeFile} />
    </Box>
  );
};
