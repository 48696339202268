import { z } from 'zod';

export const constructionItwoProjectAdditionalInformationSchema = z.object({
  projectType: z.literal('CONSTRUCTION_I_TWO'),
  engineeringOffice: z
    .object({
      name: z.string(),
      address: z
        .object({
          street: z.string().optional(),
          zipCode: z.string().optional(),
          city: z.string().optional(),
          country: z.string().optional()
        })
        .optional(),
      phone: z.string().optional(),
      email: z.string().optional()
    })
    .optional(),
  owner: z
    .object({
      name: z.string(),
      address: z
        .object({
          street: z.string().optional(),
          zipCode: z.string().optional(),
          city: z.string().optional(),
          country: z.string().optional()
        })
        .optional(),
      phone: z.string().optional(),
      email: z.string().optional()
    })
    .optional(),
  openDate: z.string().optional(),
  submissionDeadlineDate: z.string().optional()
});

export const projectAdditionalInformationSchema = z.discriminatedUnion('projectType', [
  constructionItwoProjectAdditionalInformationSchema
]);

export type ProjectAdditionalInformation = z.infer<typeof projectAdditionalInformationSchema>;
