import { z } from 'zod';
import { tableRowDataSchema } from '../../table';
import {
  proposedChangeDataTypeSchema,
  proposedChangeStatusSchema,
  proposedChangeTypeSchema
} from './enums';

export const proposedChangeValueSchema = tableRowDataSchema;
export type ProposedChangeValue = z.infer<typeof proposedChangeValueSchema>;

export const proposedChangeDtoSchema = z.object({
  id: z.string(),
  targetId: z.string(),
  value: proposedChangeValueSchema.nullable(),
  type: proposedChangeTypeSchema,
  dataType: proposedChangeDataTypeSchema,
  status: proposedChangeStatusSchema
});
export type ProposedChangeDto = z.infer<typeof proposedChangeDtoSchema>;

export const createProposedChangeSchema = z.object({
  targetId: z.string(),
  type: proposedChangeTypeSchema,
  dataType: proposedChangeDataTypeSchema,
  value: proposedChangeValueSchema.nullable()
});
export type CreateProposedChange = z.infer<typeof createProposedChangeSchema>;
