import {
  BooleanFilterOperator,
  DateFilterOperator,
  JsonbFilterOperator,
  NumberFilterOperator,
  TableFilter,
  TableFilterGroup,
  TableFilterType,
  TableRowDto,
  TextFilterOperator
} from '@company/common/types';

export const filterTableRows = <TTableRow extends TableRowDto>({
  rows,
  filters
}: {
  rows: TTableRow[];
  filters: TableFilterGroup[];
}) => {
  const evaluateFilterGroup = (filterGroup: TableFilterGroup, row: TTableRow): boolean => {
    if (filterGroup.type === 'AND') {
      return filterGroup.filters.every(filter => {
        if (isTableFilter(filter)) {
          return filterTypeToShouldFilter[filter.type](filter, row);
        }
        return evaluateFilterGroup(filter, row);
      });
    } else {
      return filterGroup.filters.some(filter => {
        if (isTableFilter(filter)) {
          return filterTypeToShouldFilter[filter.type](filter, row);
        }
        return evaluateFilterGroup(filter, row);
      });
    }
  };

  return rows.filter(row => filters.every(filterGroup => evaluateFilterGroup(filterGroup, row)));
};

const filterTypeToShouldFilter: Record<
  TableFilterType,
  <TTableRow extends TableRowDto>(filter: TableFilter, row: TTableRow) => boolean
> = {
  TEXT: (filter, row) => {
    if (filter.type !== 'TEXT') {
      throw new Error('Invalid filter type');
    }

    const fieldValue = row[filter.fieldId] as string | null | undefined;

    const operatorToShouldFilter: Record<TextFilterOperator, () => boolean> = {
      CONTAINS: () => {
        return !!fieldValue?.includes(filter.value ?? '');
      },
      EMPTY: () => {
        return fieldValue === null || fieldValue === undefined;
      },
      STARTS_WITH: () => {
        return !!fieldValue?.startsWith(filter.value ?? '');
      },
      ENDS_WITH: () => {
        return !!fieldValue?.endsWith(filter.value ?? '');
      },
      NOT_CONTAINS: () => {
        return !operatorToShouldFilter.CONTAINS();
      },
      NOT_EMPTY: () => {
        return !operatorToShouldFilter.EMPTY();
      },
      EQUALS: () => {
        return fieldValue === filter.value;
      },
      NOT_EQUALS: () => {
        return fieldValue !== filter.value;
      }
    };
    return operatorToShouldFilter[filter.operator]();
  },
  DATE: (filter, row) => {
    if (filter.type !== 'DATE') {
      throw new Error('Invalid filter type');
    }

    const fieldValue = row[filter.fieldId] as Date | null | undefined;

    const operatorToShouldFilter: Record<DateFilterOperator, () => boolean> = {
      EMPTY: () => {
        return fieldValue === null || fieldValue === undefined;
      },
      NOT_EMPTY: () => {
        return !operatorToShouldFilter.EMPTY();
      },
      AFTER: () => {
        return !!(fieldValue && filter.value && fieldValue > filter.value);
      },
      BEFORE: () => {
        return !!(fieldValue && filter.value && fieldValue < filter.value);
      },
      EQUALS: () => {
        return !!(fieldValue && filter.value && fieldValue.getTime() === filter.value.getTime());
      },
      NOT_EQUALS: () => {
        return !operatorToShouldFilter.EQUALS();
      }
    };
    return operatorToShouldFilter[filter.operator]();
  },
  NUMBER: (filter, row) => {
    if (filter.type !== 'NUMBER') {
      throw new Error('Invalid filter type');
    }

    const fieldValue = row[filter.fieldId] as number | null | undefined;

    const operatorToShouldFilter: Record<NumberFilterOperator, () => boolean> = {
      EQUALS: () => {
        return fieldValue === filter.value;
      },
      NOT_EQUALS: () => {
        return fieldValue !== filter.value;
      },
      EMPTY: () => {
        return fieldValue === null || fieldValue === undefined;
      },
      GREATER_THAN: () => {
        return !!(fieldValue && filter.value && fieldValue > filter.value);
      },
      LESS_THAN: () => {
        return !!(fieldValue && filter.value && fieldValue < filter.value);
      },
      GREATER_THAN_OR_EQUALS: () => {
        return !!(fieldValue && filter.value && fieldValue >= filter.value);
      },
      LESS_THAN_OR_EQUALS: () => {
        return !!(fieldValue && filter.value && fieldValue <= filter.value);
      },
      NOT_EMPTY: () => {
        return !operatorToShouldFilter.EMPTY();
      }
    };
    return operatorToShouldFilter[filter.operator]();
  },
  BOOLEAN: (filter, row) => {
    if (filter.type !== 'BOOLEAN') {
      throw new Error('Invalid filter type');
    }

    const fieldValue = row[filter.fieldId] as boolean | null | undefined;

    const operatorToShouldFilter: Record<BooleanFilterOperator, () => boolean> = {
      IS_TRUE: () => {
        return fieldValue === true;
      },
      IS_FALSE: () => {
        return fieldValue === false || fieldValue === null || fieldValue === undefined;
      }
    };
    return operatorToShouldFilter[filter.operator]();
  },
  JSONB: (filter, row) => {
    if (filter.type !== 'JSONB') {
      throw new Error('Invalid filter type');
    }

    const fieldValue = row[filter.fieldId] as object | null | undefined;

    const operatorToShouldFilter: Record<JsonbFilterOperator, () => boolean> = {
      CONTAINS: () => {
        return !!fieldValue?.toString().includes(filter.value ?? '');
      },
      NOT_CONTAINS: () => {
        return !operatorToShouldFilter.CONTAINS();
      },
      IS_NULL: () => {
        return fieldValue === null || fieldValue === undefined;
      },
      IS_NOT_NULL: () => {
        return !operatorToShouldFilter.IS_NULL();
      }
    };

    return operatorToShouldFilter[filter.operator]();
  }
};

const isTableFilter = (filter: TableFilter | TableFilterGroup): filter is TableFilter => {
  return 'type' in filter && filter.type !== 'AND' && filter.type !== 'OR';
};
