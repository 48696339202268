'use client';

import { FieldValues } from '@company/ui/hooks/form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { Textarea, TextareaProps } from '..';

export const TextareaInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  autoFocus,
  size = 'md',
  backgroundColor = 'white',
  placeholder,
  ...textareaProps
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder: TextareaProps['placeholder'];
  rows?: TextareaProps['rows'];
  backgroundColor?: TextareaProps['backgroundColor'];
}) => {
  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={label}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Textarea
        size={size}
        autoFocus={autoFocus}
        backgroundColor={backgroundColor}
        placeholder={placeholder}
        {...form.register(name)}
        {...textareaProps}
      />
    </FormInput>
  );
};
