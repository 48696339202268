'use client';

export {
  TagsInputClearTrigger as ClearTrigger,
  TagsInputControl as Control,
  TagsInputHiddenInput as HiddenInput,
  TagsInputInput as Input,
  TagsInputItem as Item,
  TagsInputItemText as ItemText,
  TagsInputItemDeleteTrigger as ItemDeleteTrigger,
  TagsInputItemInput as ItemInput,
  TagsInputItemPreview as ItemPreview,
  TagsInputLabel as Label,
  TagsInputContext as Context,
  TagsInputRoot as Root
} from './tags-input';

export type {
  TagsInputClearTriggerProps as ClearTriggerProps,
  TagsInputControlProps as ControlProps,
  TagsInputInputProps as InputProps,
  TagsInputItemDeleteTriggerProps as ItemDeleteTriggerProps,
  TagsInputItemInputProps as ItemInputProps,
  TagsInputItemPreviewProps as ItemPreviewProps,
  TagsInputItemProps as ItemProps,
  TagsInputItemTextProps as ItemTextProps,
  TagsInputLabelProps as LabelProps,
  TagsInputRootProps as RootProps
} from './tags-input';
