import { z } from 'zod';

export const addProjectMemberSchema = z.object({
  userId: z.string()
});

export const addProjectMembersSchema = z.object({
  projectId: z.string(),
  members: z.array(addProjectMemberSchema)
});
