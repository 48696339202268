'use client';

import { TagsInput as ArkTagsInput } from '@ark-ui/react/tags-input';
import {
  type HTMLChakraProps,
  type SlotRecipeProps,
  type UnstyledProp,
  createSlotRecipeContext
} from '@chakra-ui/react';

////////////////////////////////////////////////////////////////////////////////////

const {
  withProvider,
  withContext,
  useStyles: useTagsInputStyles
} = createSlotRecipeContext({ key: 'tagsInput' });

export { useTagsInputStyles };

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputRootProps
  extends HTMLChakraProps<'div', ArkTagsInput.RootProps>,
    SlotRecipeProps<'tagsInput'>,
    UnstyledProp {}

export const TagsInputRoot = withProvider<HTMLDivElement, TagsInputRootProps>(
  ArkTagsInput.Root,
  'root',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputLabelProps extends HTMLChakraProps<'label', ArkTagsInput.LabelProps> {}

export const TagsInputLabel = withContext<HTMLLabelElement, TagsInputLabelProps>(
  ArkTagsInput.Label,
  'label',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputControlProps extends HTMLChakraProps<'div', ArkTagsInput.ControlProps> {}

export const TagsInputControl = withContext<HTMLDivElement, TagsInputControlProps>(
  ArkTagsInput.Control,
  'control',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputInputProps extends HTMLChakraProps<'input', ArkTagsInput.InputProps> {}

export const TagsInputInput = withContext<HTMLInputElement, TagsInputInputProps>(
  ArkTagsInput.Input,
  'input',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputClearTriggerProps
  extends HTMLChakraProps<'button', ArkTagsInput.ClearTriggerProps> {}

export const TagsInputClearTrigger = withContext<HTMLButtonElement, TagsInputClearTriggerProps>(
  ArkTagsInput.ClearTrigger,
  'clearTrigger',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputItemProps extends HTMLChakraProps<'div', ArkTagsInput.ItemProps> {}

export const TagsInputItem = withContext<HTMLDivElement, TagsInputItemProps>(
  ArkTagsInput.Item,
  'item',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputItemTextProps
  extends HTMLChakraProps<'span', ArkTagsInput.ItemTextProps> {}

export const TagsInputItemText = withContext<HTMLSpanElement, TagsInputItemTextProps>(
  ArkTagsInput.ItemText,
  'itemText',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputItemDeleteTriggerProps
  extends HTMLChakraProps<'button', ArkTagsInput.ItemDeleteTriggerProps> {}

export const TagsInputItemDeleteTrigger = withContext<
  HTMLButtonElement,
  TagsInputItemDeleteTriggerProps
>(ArkTagsInput.ItemDeleteTrigger, 'itemDeleteTrigger', { forwardAsChild: true });

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputItemInputProps
  extends HTMLChakraProps<'input', ArkTagsInput.ItemInputProps> {}

export const TagsInputItemInput = withContext<HTMLInputElement, TagsInputItemInputProps>(
  ArkTagsInput.ItemInput,
  'itemInput',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface TagsInputItemPreviewProps
  extends HTMLChakraProps<'div', ArkTagsInput.ItemPreviewProps> {}

export const TagsInputItemPreview = withContext<HTMLDivElement, TagsInputItemPreviewProps>(
  ArkTagsInput.ItemPreview,
  'itemPreview',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export const TagsInputContext = ArkTagsInput.Context;
export const TagsInputItemContext = ArkTagsInput.ItemContext;
export const TagsInputHiddenInput = ArkTagsInput.HiddenInput;
