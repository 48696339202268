import { z } from 'zod';
import {
  proposedChangeStatusSchema,
  proposedChangeTypeSchema
} from '../piece-of-information/change-proposal/enums';

export const tableRowPrimitiveDataTypeSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.date(),
  z.string().array(),
  z.null()
]);

const linkedRowRecommendationStatusSchema = z.enum([
  'PENDING',
  'IN_PROGRESS',
  'COMPLETED',
  'FAILED'
]);
export const tableRowLinkedRowTypeSchema = z.object({
  linkedRows: z
    .object({
      linkedRowId: z.string(),
      primaryFieldValue: tableRowPrimitiveDataTypeSchema,
      recommendationScore: z.number().nullable()
    })
    .array(),
  recommendationStatus: linkedRowRecommendationStatusSchema.nullish()
});

const tableRowDataTypeWithoutProposedChangeSchema = z.union([
  tableRowPrimitiveDataTypeSchema,
  tableRowLinkedRowTypeSchema
]);
export type TableRowDataTypeWithoutProposedChange = z.infer<
  typeof tableRowDataTypeWithoutProposedChangeSchema
>;

const tableRowProposedChangeTypeSchema = z.object({
  oldValue: z.record(z.string(), tableRowDataTypeWithoutProposedChangeSchema).nullable(),
  type: proposedChangeTypeSchema,
  status: proposedChangeStatusSchema
});

export const tableRowDataTypeSchema = z.union([
  tableRowDataTypeWithoutProposedChangeSchema,
  tableRowProposedChangeTypeSchema
]);

export const tableRowDataSchema = z.record(z.string(), tableRowDataTypeSchema);

export const tableRowDtoSchema = z
  .object({
    id: z.string(),
    parentRowId: z.string().nullable(),
    parentTableRowId: z.string().nullable(),
    primaryFieldValue: tableRowPrimitiveDataTypeSchema.nullable(),
    proposedChange: tableRowProposedChangeTypeSchema.nullable()
  })
  .catchall(tableRowDataTypeSchema);

export type TableRowPrimitiveDataType = z.infer<typeof tableRowPrimitiveDataTypeSchema>;
export type TableRowDataType = z.infer<typeof tableRowDataTypeSchema>;
export type TableRowData = z.infer<typeof tableRowDataSchema>;
export type TableRowLinkedRowType = z.infer<typeof tableRowLinkedRowTypeSchema>;
export type TableRowDto = z.infer<typeof tableRowDtoSchema>;

const upsertTableRowLinkedRowTypeSchema = z.object({
  linkedRows: z
    .object({
      linkedRowId: z.string(),
      primaryFieldValue: tableRowPrimitiveDataTypeSchema.nullish(),
      recommendationScore: z.number().nullish()
    })
    .array(),
  recommendationStatus: linkedRowRecommendationStatusSchema.nullish()
});

export const upsertTableRowDataTypeSchema = z
  .union([tableRowPrimitiveDataTypeSchema, upsertTableRowLinkedRowTypeSchema])
  .nullish();
export const upsertTableRowSchema = z
  .object({
    id: z.string(),
    parentRowId: z.string().nullish(),
    parentTableRowId: z.string().nullish()
  })
  .catchall(upsertTableRowDataTypeSchema);

export type UpsertTableRowLinkedRowType = z.infer<typeof upsertTableRowLinkedRowTypeSchema>;
export type UpsertTableRowDataType = z.infer<typeof upsertTableRowDataTypeSchema>;
export type UpsertTableRow = z.infer<typeof upsertTableRowSchema>;

export const tableRowFilterSchema = z.object({
  parentTableRowId: z.string().optional(),
  rowIds: z.array(z.string()).optional(),
  isLeafRow: z.boolean().optional()
});

export type TableRowFilter = z.infer<typeof tableRowFilterSchema>;
