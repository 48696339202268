'use client';

import { Combobox, ComboboxRootProps } from '../combobox';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { FieldValues } from '@company/ui/hooks/form';
import { SelectOption } from '@company/ui/types/select';
import { Controller } from 'react-hook-form';
import { Box, createListCollection, Flex, Portal } from '@chakra-ui/react';
import React from 'react';

export const ComboboxFormInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  placeholder,
  helperText,
  helperProps,
  errorProps,
  hideError,
  size = 'md',
  options,
  isRequired,
  isReadOnly,
  isMultiSelect = false,
  backgroundColor = 'white'
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder?: string;
  backgroundColor?: ComboboxRootProps['backgroundColor'];
  options: SelectOption<unknown>[];
  isMultiSelect?: boolean;
}) => {
  const [inputValue, setInputValue] = React.useState<string>('');

  const filteredCollection = React.useMemo(() => {
    return createListCollection({
      items: options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase()))
    });
  }, [options, inputValue]);

  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={label}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Controller
        control={form.control}
        name={name}
        render={({ field }) => (
          <Combobox.Root
            collection={filteredCollection}
            closeOnSelect={true}
            value={field.value === null ? [] : !isMultiSelect ? [field.value] : field.value}
            onValueChange={({ value }) => {
              field.onChange(!isMultiSelect ? value[0] : value);
            }}
            placeholder={placeholder}
            openOnClick={true}
            inputValue={inputValue}
            onInputValueChange={({ inputValue }) => setInputValue(inputValue)}
            required={isRequired}
            readOnly={isReadOnly}
            disabled={isDisabled}
            multiple={isMultiSelect}
            backgroundColor={backgroundColor}
          >
            <Combobox.Control>
              <Combobox.Input />
            </Combobox.Control>
            <Portal>
              <Combobox.Positioner zIndex={'1000 !important'}>
                <Combobox.Content h={'full'} overflowY={'auto'}>
                  <Combobox.ItemGroup>
                    {filteredCollection.items.map(item => (
                      <Combobox.Item key={item.value} item={item} fontSize={size}>
                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                          <Combobox.ItemText>{item.label}</Combobox.ItemText>
                          <Combobox.ItemIndicator>✓</Combobox.ItemIndicator>
                        </Flex>
                      </Combobox.Item>
                    ))}
                    {filteredCollection.items.length === 0 && (
                      <Box color={'gray.500'}>No options found</Box>
                    )}
                  </Combobox.ItemGroup>
                </Combobox.Content>
              </Combobox.Positioner>
            </Portal>
          </Combobox.Root>
        )}
      />
    </FormInput>
  );
};
