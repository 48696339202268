import { discriminatedUnion, z } from 'zod';
import { pieceOfInformationDtoSchema } from '../piece-of-information/dto';

export const projectDashboardItemTypeSchema = z.enum([
  'HORIZONTAL_PIECE_OF_INFORMATION_LIST',
  'RICH_TEXT',
  'STATISTIC'
]);
export type ProjectDashboardItemType = z.infer<typeof projectDashboardItemTypeSchema>;

export const PROJECT_TYPE_TO_DASHBOARD_ITEM_REFERENCE_ID = {
  CONSTRUCTION_I_TWO: {
    projectInformation: 'projectInformation',
    projectSummary: 'projectSummary',
    projectFiles: 'projectFiles',
    similarProjects: 'similarProjects'
  }
} as const;

const projectDashboardItemContentPieceOfInformationSchema = z.object({
  type: z.literal('HORIZONTAL_PIECE_OF_INFORMATION_LIST'),
  piecesOfInformation: z
    .array(
      z.object({
        id: z.string()
      })
    )
    .nullable(),
  emptyText: z.string(),
  title: z.string(),
  subTitle: z.string().optional()
});
const projectDashboardItemContentPieceOfInformationDtoSchema =
  projectDashboardItemContentPieceOfInformationSchema
    .omit({
      piecesOfInformation: true
    })
    .extend({
      piecesOfInformation: z.array(pieceOfInformationDtoSchema).nullable()
    });

const projectDashboardItemContentRichTextSchema = z.object({
  type: z.literal('RICH_TEXT'),
  text: z.string().nullable(),
  title: z.string().optional(),
  subTitle: z.string().optional(),
  emptyText: z.string()
});

const projectDashboardItemContentStatisticSchema = z.object({
  type: z.literal('STATISTIC'),
  label: z.string(),
  value: z.string().nullable(),
  infoText: z.string()
});

export const projectDashboardItemContentSchema = z
  .object({
    type: projectDashboardItemTypeSchema
  })
  .and(
    discriminatedUnion('type', [
      projectDashboardItemContentPieceOfInformationSchema,
      projectDashboardItemContentRichTextSchema,
      projectDashboardItemContentStatisticSchema
    ])
  );

export type ProjectDashboardItemContent = z.infer<typeof projectDashboardItemContentSchema>;

export const projectDashboardItemContentDtoSchema = z
  .object({
    type: projectDashboardItemTypeSchema
  })
  .and(
    discriminatedUnion('type', [
      projectDashboardItemContentPieceOfInformationDtoSchema,
      projectDashboardItemContentRichTextSchema,
      projectDashboardItemContentStatisticSchema
    ])
  );
export type ProjectDashboardItemContentDto = z.infer<typeof projectDashboardItemContentDtoSchema>;

type ProjectDashboardItem = {
  referenceId: string;
  isHidden: boolean;
  content: ProjectDashboardItemContent;
  canUserEdit: boolean;
  canUserRegenerate: boolean;
};

type ProjectDashboardGroup = {
  title?: string;
  description?: string;
  items: ProjectDashboardItem[];
};

export type CreateProjectDashboard = {
  groups: ProjectDashboardGroup[];
};
