import { z } from 'zod';
import {
  fieldLinkedRowConfigSchema,
  fieldNumberConfigSchema,
  fieldSelectConfigSchema,
  fieldTypeSchema
} from './field';
import { tableRowDtoSchema } from './row';
import { viewStateSchema, viewTypeSchema } from './view';
import { tableDataTypeSchema } from './data-type';

export const tableRowConfigLinkedTypeSchema = z.enum(['SINGLE_ROW', 'MULTIPLE_ROWS']);
export type TableRowConfigLinkedType = z.infer<typeof tableRowConfigLinkedTypeSchema>;

export const fieldDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  referenceId: z.string(),
  type: fieldTypeSchema,
  isEditable: z.boolean(),
  isPrimary: z.boolean(),
  linkedRowConfig: fieldLinkedRowConfigSchema.nullable(),
  selectConfig: fieldSelectConfigSchema.nullable(),
  numberConfig: fieldNumberConfigSchema.nullable()
});
export type FieldDto = z.infer<typeof fieldDtoSchema>;

export const viewDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: viewTypeSchema,
  state: viewStateSchema
});
export type ViewDto = z.infer<typeof viewDtoSchema>;

export const tableDtoSchema = z.object({
  id: z.string(),
  changeProposalId: z.string().nullable(),
  name: z.string(),
  dataType: tableDataTypeSchema.nullable(),
  parentTableId: z.string().nullable(),
  childTableId: z.string().nullable(),
  activeViewId: z.string(),
  pieceOfInformationId: z.string(),
  views: z.array(viewDtoSchema),
  fields: z.array(fieldDtoSchema),
  rows: z.array(tableRowDtoSchema)
});
export type TableDto = z.infer<typeof tableDtoSchema>;
