import {
  TableRowDataType,
  TableRowDto,
  TableRowLinkedRowType,
  UpsertTableRow,
  UpsertTableRowDataType,
  UpsertTableRowLinkedRowType
} from '../../../types';
import { formatNumber } from '../../number';
import { removeHtmlTags } from '../../strings';

export const rowValueToString = (value: TableRowDataType | undefined): string => {
  if (value === undefined) {
    return '';
  }
  if (isTableRowLinkedRowTypeArray(value)) {
    return value.linkedRows.map(linkedRow => linkedRow.primaryFieldValue).join(', ');
  }
  if (typeof value === 'number') {
    return formatNumber(value) ?? '';
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  if (value === null) {
    return '';
  }
  return value.toString();
};

export const rowValueToNumber = (value: TableRowDataType | undefined): number => {
  if (value === undefined) {
    return 0;
  }
  return Number(value);
};

export const rowValueToExportFormat = (value: TableRowDataType | undefined): string => {
  if (value === undefined) {
    return '';
  }

  if (isTableRowLinkedRowTypeArray(value)) {
    return value.linkedRows
      .map(
        linkedRow =>
          `${linkedRow.recommendationScore ? `(${linkedRow.recommendationScore?.toFixed(1)}%) ` : ''}${linkedRow.primaryFieldValue?.toString().trim()}`
      )
      .join(', ');
  }
  return removeHtmlTags(rowValueToString(value)).trim();
};

export const isTableRowLinkedRowTypeArray = (
  value: TableRowDataType
): value is TableRowLinkedRowType => {
  return typeof value === 'object' && value !== null && 'linkedRows' in value;
};

export const isUpsertTableRowLinkedRowTypeArray = (
  value: UpsertTableRowDataType
): value is UpsertTableRowLinkedRowType => {
  return typeof value === 'object' && value !== null && 'linkedRows' in value;
};

type TableRowDtoWithOptionalFields = Omit<
  TableRowDto,
  'parentRowId' | 'parentTableRowId' | 'proposedChange' | 'primaryFieldValue'
> & {
  id: string;
  parentRowId?: string | null;
  parentTableRowId?: string | null;
  primaryFieldValue?: TableRowDataType;
  proposedChange?: any;
};

export const convertDtoToUpsertTableRow = (dto: TableRowDtoWithOptionalFields): UpsertTableRow => {
  const { id, parentRowId, parentTableRowId, primaryFieldValue, proposedChange, ...rest } = dto;
  return {
    id,
    parentRowId,
    parentTableRowId,
    ...rest
  };
};
