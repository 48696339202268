import { isReservedFieldId } from '@company/common/lib';
import {
  ProposedChangeDto,
  removeNullish,
  TableRowDataTypeWithoutProposedChange,
  TableRowDto
} from '@company/common/types';

type ProposedChange = ProposedChangeDto;

export const addProposedChangesToTableRows = ({
  rows,
  proposedChanges
}: {
  rows: TableRowDto[];
  proposedChanges: ProposedChange[];
}): TableRowDto[] => {
  if (proposedChanges.length === 0) return rows;

  const inserts = proposedChanges.filter(
    change => change.type === 'INSERT' && change.dataType === 'TABLE_ROW'
  );
  const rowIdToProposedChange = new Map<string, ProposedChange>();
  proposedChanges.forEach(change => {
    rowIdToProposedChange.set(change.targetId, change);
  });

  const newRows = rows
    .map((row): TableRowDto => {
      const proposedChange = rowIdToProposedChange.get(row.id) ?? null;
      if (!proposedChange) {
        return row;
      }

      const { proposedChange: _, ...rest } = row;

      return {
        ...row,
        ...(proposedChange.value ?? {}),
        proposedChange: {
          oldValue: rest as Record<string, TableRowDataTypeWithoutProposedChange>,
          type: proposedChange.type,
          status: proposedChange.status
        }
      };
    })
    .filter(removeNullish);

  newRows.push(
    ...inserts.map(
      (proposedChange): TableRowDto => ({
        ...convertToTableRowDto(proposedChange.value!),
        proposedChange: {
          type: proposedChange.type,
          status: proposedChange.status,
          oldValue: null
        }
      })
    )
  );

  // remove duplciate row ids
  const uniqueRows = Array.from(new Map(newRows.map(row => [row.id, row])).values());

  return uniqueRows;
};

const convertToTableRowDto = (
  proposedChangeValue: NonNullable<ProposedChange['value']>
): TableRowDto => {
  return {
    id: proposedChangeValue.id as string,
    parentRowId: proposedChangeValue.parentRowId as string | null,
    parentTableRowId: proposedChangeValue.parentTableRowId as string | null,
    primaryFieldValue: null,
    ...Object.fromEntries(
      Object.entries(proposedChangeValue)
        .filter(([key]) => !isReservedFieldId(key))
        .map(([key, value]) => {
          return [key, value];
        })
    ),
    proposedChange: null
  };
};
