'use client';

import { uuid } from '@company/common/lib';
import { logger } from '@company/common/logger';
import { FileKnowledgeLibraryConfig } from '@company/common/types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FileUploadStatus, UploadedFile, UploadingFile } from '../components/file/input/types';

interface UseFileUploadProps {
  maxSize: number;
  acceptedFileTypes?: { [key: string]: string[] };
  maxFiles?: number;
  uploadFile: (args: {
    fileId: string;
    file: File;
    knowledgeLibrary: FileKnowledgeLibraryConfig;
    onProgress: (progress: number) => void;
  }) => Promise<{ id: string; status: FileUploadStatus; pieceOfInformationId: string | null }>;
  onChange: (files: UploadedFile[]) => void;
  defaultFiles: UploadedFile[];
  knowledgeLibrary: FileKnowledgeLibraryConfig;
}

interface QueuedFile {
  id: string;
  file: File;
  progress: number;
}

const getDefaultUploadedFiles = (
  defaultFiles: { id: string; name: string; pieceOfInformationId: string }[]
): UploadingFile[] => {
  return defaultFiles.map(file => ({
    id: file.id,
    name: file.name,
    extension: file.name.split('.').pop() || '',
    size: 0,
    status: 'UPLOADED',
    progress: 100,
    file: new File([], file.name),
    uploadedOn: new Date(),
    pieceOfInformationId: file.pieceOfInformationId
  }));
};

export const useFileUpload = ({
  maxSize,
  acceptedFileTypes = {},
  maxFiles = 1,
  uploadFile,
  onChange,
  defaultFiles,
  knowledgeLibrary
}: UseFileUploadProps) => {
  const [queuedFiles, setQueuedFiles] = React.useState<QueuedFile[]>([]);
  const [uploadingFiles, setUploadingFiles] = React.useState<UploadingFile[]>([]);
  const [uploadedFiles, setUploadedFiles] = React.useState<UploadingFile[]>(
    getDefaultUploadedFiles(defaultFiles)
  );
  const removedFileIdsRef = React.useRef<Set<string>>(new Set());
  const lastChangedFilesRef = React.useRef<UploadedFile[]>(undefined);

  // Handle file drop
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      const allowedFiles = acceptedFiles.slice(
        0,
        maxFiles - uploadedFiles.length - uploadingFiles.length
      );
      setQueuedFiles(prevFiles => [
        ...prevFiles,
        ...allowedFiles.map(file => ({ id: uuid(), file, progress: 0 }))
      ]);
    },
    [uploadedFiles.length, uploadingFiles.length, maxFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize,
    accept: acceptedFileTypes,
    maxFiles,
    multiple: maxFiles > 1
  });

  // Remove a file from all states and prevent it from being re-added after upload
  const removeFile = (fileId: string) => {
    removedFileIdsRef.current.add(fileId);
    setUploadedFiles(prev => prev.filter(file => file.id !== fileId));
    setUploadingFiles(prev => prev.filter(file => file.id !== fileId));
    onChange(
      uploadedFiles
        .filter(file => file.id !== fileId)
        .map(file => ({
          id: file.id!,
          name: file.name,
          extension: file.extension,
          pieceOfInformationId: file.pieceOfInformationId!
        }))
    );
  };

  React.useEffect(() => {
    if (queuedFiles.length === 0) return;

    const filesToUpload = [...queuedFiles];
    setQueuedFiles([]);

    const newUploadingFiles = filesToUpload.map(({ id, file }) => ({
      id,
      name: file.name,
      extension: file.name.split('.').pop() || '',
      size: file.size,
      status: 'UPLOADING' as FileUploadStatus,
      progress: 0,
      file,
      uploadedOn: new Date(),
      pieceOfInformationId: null
    }));

    setUploadingFiles(prev => [...prev, ...newUploadingFiles]);

    newUploadingFiles.forEach(uploadingFile => {
      const { id } = uploadingFile;

      uploadFile({
        fileId: id,
        file: uploadingFile.file,
        knowledgeLibrary,
        onProgress: progress => {
          if (removedFileIdsRef.current.has(id)) return;
          setUploadingFiles(prev =>
            prev.map(file => (file.id === id ? { ...file, progress } : file))
          );
        }
      })
        .then(({ id, status, pieceOfInformationId }) => {
          setUploadingFiles(prev => prev.filter(file => file.id !== id));

          if (removedFileIdsRef.current.has(id)) return;

          const uploadedFile = {
            ...uploadingFile,
            status,
            pieceOfInformationId
          };

          setUploadedFiles(uploadedFiles => [...uploadedFiles, uploadedFile].slice(-maxFiles));
        })
        .catch(error => {
          // Handle upload error if necessary
          logger.error('Upload error:', error);
          setUploadingFiles(prev => prev.filter(file => file.id !== id));
        });
    });
  }, [queuedFiles, uploadedFiles]);

  React.useEffect(() => {
    const changedFiles = uploadedFiles.map(file => ({
      id: file.id!,
      name: file.name,
      extension: file.extension,
      pieceOfInformationId: file.pieceOfInformationId!
    }));

    if (JSON.stringify(changedFiles) !== JSON.stringify(lastChangedFilesRef.current)) {
      lastChangedFilesRef.current = changedFiles;
      onChange(changedFiles);
    }
  }, [uploadedFiles, onChange]);

  return {
    files: [...uploadedFiles, ...uploadingFiles],
    removeFile,
    removeAllFiles: () => {
      const allFileIds = [...uploadedFiles, ...uploadingFiles].map(file => file.id);
      allFileIds.forEach(id => removedFileIdsRef.current.add(id));
      setUploadedFiles([]);
      setUploadingFiles([]);
      setQueuedFiles([]);
      onChange([]);
    },
    removeFiles: (fileIds: string[]) => {
      fileIds.forEach(id => removedFileIdsRef.current.add(id));
      setUploadedFiles(prev => prev.filter(file => !fileIds.includes(file.id)));
      setUploadingFiles(prev => prev.filter(file => !fileIds.includes(file.id)));
      onChange(
        uploadedFiles
          .filter(file => !fileIds.includes(file.id))
          .map(file => ({
            id: file.id!,
            name: file.name,
            extension: file.extension,
            pieceOfInformationId: file.pieceOfInformationId!
          }))
      );
    },
    getRootProps,
    onOpen: () => getRootProps().onClick!({} as any),
    getInputProps,
    isDragActive
  };
};
