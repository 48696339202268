import {
  PieceOfInformationDto,
  PieceOfInformationType,
  ProjectAdditionalInformation,
  ProjectType,
  TableDataType
} from '../../types';

export type PieceOfInformationForDto = {
  id: string;
  type: PieceOfInformationType;
  createdOn: Date | string;
  table: {
    id: string;
    name: string;
    dataType: TableDataType | null;
    views: {
      id: string;
    }[];
  } | null;
  file: {
    id: string;
    name: string;
    extension: string;
  } | null;
  project: {
    id: string;
    name: string;
    description: string | null;
    type: ProjectType | null;
    additionalInformation: ProjectAdditionalInformation | null;
  } | null;
  conversation: {
    id: string;
    name: string;
    projectId: string | null;
  } | null;
};

export const convertToPieceOfInformationDto = (
  pieceOfInformation: PieceOfInformationForDto
): PieceOfInformationDto => {
  const common = {
    id: pieceOfInformation.id,
    itemId:
      pieceOfInformation.file?.id ??
      pieceOfInformation.table?.id ??
      pieceOfInformation.project?.id ??
      pieceOfInformation.conversation?.id!,
    createdOn:
      typeof pieceOfInformation.createdOn === 'string'
        ? new Date(pieceOfInformation.createdOn)
        : pieceOfInformation.createdOn
  };

  switch (pieceOfInformation.type) {
    case 'TABLE':
      return {
        ...common,
        type: 'TABLE',
        name: pieceOfInformation.table!.name,
        table: {
          id: pieceOfInformation.table!.id,
          name: pieceOfInformation.table!.name,
          dataType: pieceOfInformation.table!.dataType,
          pieceOfInformationId: pieceOfInformation.id,
          defaultViewId: pieceOfInformation.table!.views[0]!.id
        }
      };
    case 'FILE':
      return {
        ...common,
        type: 'FILE',
        name: pieceOfInformation.file!.name,
        file: {
          id: pieceOfInformation.file!.id,
          name: pieceOfInformation.file!.name,
          extension: pieceOfInformation.file!.extension,
          pieceOfInformationId: pieceOfInformation.id
        }
      };
    case 'PROJECT':
      return {
        ...common,
        type: 'PROJECT',
        name: pieceOfInformation.project!.name,
        project: {
          id: pieceOfInformation.project!.id,
          name: pieceOfInformation.project!.name,
          description: pieceOfInformation.project!.description,
          type: pieceOfInformation.project!.type,
          additionalInformation: pieceOfInformation.project!.additionalInformation,
          pieceOfInformationId: pieceOfInformation.id
        }
      };
    case 'CONVERSATION':
      return {
        ...common,
        type: 'CONVERSATION',
        name: pieceOfInformation.conversation!.name,
        conversation: {
          id: pieceOfInformation.conversation!.id,
          name: pieceOfInformation.conversation!.name,
          projectId: pieceOfInformation.conversation!.projectId,
          pieceOfInformationId: pieceOfInformation.id
        }
      };
    default:
      throw new Error('Invalid pieceOfInformation type');
  }
};
