import { z } from 'zod';
import { addToKnowledgeLibrarySchema } from '../knowledge';
import { pieceOfInformationTypeSchema } from './dto';

export const createPieceOfInformationDtoSchema = z.object({
  type: pieceOfInformationTypeSchema,
  name: z.string(),
  knowledgeLibrary: addToKnowledgeLibrarySchema,
  organizationId: z.string().optional(),
  tableId: z.string().nullish(),
  fileId: z.string().nullish(),
  projectId: z.string().nullish(),
  conversationId: z.string().nullish()
});
export type UpsertPieceOfInformationDto = z.infer<typeof createPieceOfInformationDtoSchema>;
