'use client';

import {
  FieldErrorText,
  FieldErrorTextProps,
  Field,
  FieldRootProps,
  FieldHelperText,
  FieldHelperTextProps,
  FieldLabelProps,
  Box,
  InputProps
} from '@chakra-ui/react';
import { FieldValues, Path, UseFormReturn } from '@company/ui/hooks/form';
import React from 'react';
import { get } from 'react-hook-form';

interface FormInputProps<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  root?: FieldRootProps;
  label?: string | React.ReactElement | undefined;
  labelProps?: FieldLabelProps;
  helperText?: string | React.ReactElement | undefined;
  helperProps?: FieldHelperTextProps;
  errorProps?: FieldErrorTextProps;
  hideError?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  size?: 'sm' | 'md' | 'lg';
  autoFocus?: boolean;
  children: React.ReactElement;
}
export interface FormInputPropsWithoutChildren<TFieldValues extends FieldValues>
  extends Omit<FormInputProps<TFieldValues>, 'children'> {}

export const FormInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  helperText,
  helperProps,
  errorProps,
  hideError,
  size,
  isRequired,
  isDisabled,
  isReadOnly,
  children
}: FormInputProps<TFieldValues>) => {
  const errorMessage = get(form.formState.errors, name)?.message?.toString();
  return (
    <Field.Root
      {...root}
      invalid={!!errorMessage}
      required={isRequired}
      disabled={isDisabled}
      readOnly={isReadOnly}
    >
      {label && (
        <Field.Label fontSize={size === 'md' ? 'sm' : size === 'lg' ? 'md' : 'xs'} {...labelProps}>
          {label} {isRequired && <Field.RequiredIndicator />}
        </Field.Label>
      )}
      {children}
      {helperText && <FieldHelperText {...helperProps}>{helperText}</FieldHelperText>}
      {!hideError && <FieldErrorText {...errorProps}>{errorMessage}</FieldErrorText>}
    </Field.Root>
  );
};

export const Form = React.forwardRef<HTMLFormElement, React.FormHTMLAttributes<HTMLFormElement>>(
  ({ children, ...props }, ref) => {
    return (
      <Box w={'full'}>
        <form noValidate ref={ref} {...props}>
          {children}
        </form>
      </Box>
    );
  }
);

export * from './native-select';
export * from './number-input';
export * from './text-input';
export * from './textarea-input';
export * from './file-input';
export * from './multi-select-input';
export * from './combobox-input';
export * from './tags-combobox-input';
