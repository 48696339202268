export {
  TagsInputClearTrigger,
  TagsInputControl,
  TagsInputInput,
  TagsInputItem,
  TagsInputItemDeleteTrigger,
  TagsInputItemInput,
  TagsInputItemPreview,
  TagsInputLabel,
  TagsInputRoot,
  useTagsInputStyles
} from './tags-input';

export {
  useTagsInputContext,
  useTagsInputItemContext,
  useTagsInput
} from '@ark-ui/react/tags-input';

export type {
  TagsInputClearTriggerProps,
  TagsInputControlProps,
  TagsInputInputProps,
  TagsInputItemProps,
  TagsInputItemDeleteTriggerProps,
  TagsInputItemInputProps,
  TagsInputItemPreviewProps,
  TagsInputLabelProps,
  TagsInputRootProps
} from './tags-input';

export * as TagsInput from './namespace';
