'use client';

import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import NextLink from 'next/link';

interface LinkProps extends ChakraLinkProps {
  children: React.ReactNode;
  href: string;
}

export const Link = ({ href, children, ...props }: LinkProps) => {
  return (
    <ChakraLink {...props} asChild color="blue.600">
      <NextLink href={href}>{children}</NextLink>
    </ChakraLink>
  );
};

export const ExternalLink = ({ href, children, ...props }: LinkProps) => {
  return (
    <ChakraLink href={href} target="_blank" {...props} asChild color="blue.600">
      <a href={href}>{children}</a>
    </ChakraLink>
  );
};
export const OnClickLink = ({ onClick, children, ...props }: Omit<LinkProps, 'href'>) => {
  return (
    <ChakraLink onClick={onClick} {...props} color="blue.600">
      {children}
    </ChakraLink>
  );
};
