import { I18n } from '@lingui/core';
import { typeidUnboxed } from 'typeid-js';
import { js2xml, xml2json } from 'xml-js';
import { convertJsonToHtml, removeHtmlTags, removeImageTags } from '../../../lib';
import {
  COST_CALCULATION_TABLE_FIELDS,
  CostCalculationTableField,
  CreateTable,
  GaebProject,
  removeNullish,
  TableRowPrimitiveDataType,
  UpsertTableRow,
  WORK_ITEM_TABLE_FIELDS,
  WorkItemTableField
} from '../../../types';
import { XmlDocument, XmlElement } from '../xml-converter';
import { convertToNumber } from './converter';
import { getCreateTableConfigForITwoProject } from './table-config';
import {
  WBSItem,
  WBSItemEstDetailsCoCDetail,
  WBSItemEstDetailsCommodityDetail,
  WBSItemEstDetailsEstTextElement,
  WBSItemEstDetailsItem,
  WBSItemEstDetailsSubItem
} from './types/cost-estimation';
import { CostEstimationXml } from './types/cost-estimation-xml';
import { BoQCtgy, BoQItem, GAEB83 } from './types/gaeb-83';
import { cleanReferenceNumber, getItemsFromWbs, getItwoMajorWbsMapFromXml } from './utils';

export const getITwoProjectFromXml = ({
  iTwoCalculationXml
}: {
  iTwoCalculationXml: string;
}): CostEstimationXml => {
  const costCalculationJson: CostEstimationXml = JSON.parse(
    xml2json(iTwoCalculationXml, { compact: true, spaces: 2 })
  );
  return costCalculationJson;
};

export const getITwoProjectFromGaeb83 = ({ gaeb83Xml }: { gaeb83Xml: string }): GaebProject => {
  const json: GAEB83 = JSON.parse(xml2json(gaeb83Xml, { compact: true, spaces: 2 }));
  return {
    project: {
      name: json.GAEB.PrjInfo.NamePrj._text,
      label: json.GAEB.PrjInfo.LblPrj._text
    },
    awardInfo: {
      openDate: json.GAEB.Award.AwardInfo?.OpenDate?._text
        ? new Date(json.GAEB.Award.AwardInfo.OpenDate._text)
        : undefined,
      evalEndDate: json.GAEB.Award.AwardInfo?.EvalEnd?._text
        ? new Date(json.GAEB.Award.AwardInfo.EvalEnd._text)
        : undefined,
      owner: {
        name: json.GAEB.Award.OWN?.Address?.Name1?._text ?? '',
        address: {
          street: json.GAEB.Award.OWN?.Address?.Street?._text,
          zipCode: json.GAEB.Award.OWN?.Address?.PCode?._text,
          city: json.GAEB.Award.OWN?.Address?.City?._text
        }
      }
    },
    additionalText: {
      title: json.GAEB.Award.AddText.OutlineAddText
        ? js2xml(json.GAEB.Award.AddText.OutlineAddText, {
            compact: true,
            spaces: 2
          })
        : undefined,
      description: json.GAEB.Award.AddText.DetailAddText
        ? js2xml(json.GAEB.Award.AddText.DetailAddText, {
            compact: true,
            spaces: 2
          })
        : undefined
    }
  };
};

export const getCreateTableConfigFromITwoFiles = (
  {
    tableName,
    iTwoCalculationXml,
    gaeb83Xmls,
    projectId
  }: {
    tableName: string;
    iTwoCalculationXml: string | null;
    gaeb83Xmls: string[];
    projectId: string | null;
  },
  i18n: I18n
): CreateTable => {
  // POSITIONS
  const fieldReferenceIdToId = Object.values(WORK_ITEM_TABLE_FIELDS).reduce(
    (acc, field) => {
      acc[field.id] = typeidUnboxed('field');
      return acc;
    },
    {} as Record<WorkItemTableField, string>
  );

  const boQNameToBoqDetails = gaeb83Xmls.reduce(
    (acc, gaeb83Xml) => {
      const gaebJson: GAEB83 = JSON.parse(xml2json(gaeb83Xml, { compact: true, spaces: 2 }));
      const boqName = gaebJson.GAEB.Award.BoQ.BoQInfo.Name._text;

      acc[boqName] = {
        boqName: gaebJson.GAEB.Award.BoQ.BoQInfo.Name._text ?? null,
        labelBoq: gaebJson.GAEB.Award.BoQ.BoQInfo.LblBoQ._text ?? null,
        rows: convertGaebFileToTableRows({
          gaebJson,
          fieldReferenceIdToId,
          boqName:
            gaebJson.GAEB.Award.BoQ.BoQInfo.Name._text ??
            gaebJson.GAEB.Award.BoQ.BoQInfo.LblBoQ._text
        })
      };

      return acc;
    },
    {} as Record<
      string,
      {
        boqName: string | null;
        labelBoq: string | null;
        rows: UpsertTableRow[];
      }
    >
  );

  const boqNameAndReferenceNumberToTableRowId = Object.entries(boQNameToBoqDetails).reduce(
    (acc, [boqName, { rows }]) => {
      rows.forEach(row => {
        const referenceNumber = row[fieldReferenceIdToId.referenceNumber];
        const key = `${boqName}-${referenceNumber}`;
        if (referenceNumber) {
          acc[key] = row.id;
        }
      });
      return acc;
    },
    {} as Record<string, string>
  );

  // COST CALCULATION
  const costCalculationJson: XmlDocument | null = iTwoCalculationXml
    ? JSON.parse(xml2json(iTwoCalculationXml, { compact: false, spaces: 2 }))
    : null;
  const costCalculationTable = costCalculationJson
    ? getCostCalculationTable({
        majorWbsMap: getItwoMajorWbsMapFromXml(costCalculationJson),
        boqNameAndReferenceNumberToTableRowId
      })
    : null;

  return getCreateTableConfigForITwoProject(
    {
      projectId,
      boqTable: {
        fieldReferenceIdToId,
        rows: Object.values(boQNameToBoqDetails).flatMap(({ rows }) => rows),
        tableName
      },
      costCalculationTable: {
        fieldReferenceIdToId: costCalculationTable?.fieldReferenceIdToId,
        rows: costCalculationTable?.rows ?? []
      }
    },
    i18n
  );
};

const getCostCalculationTable = ({
  majorWbsMap,
  boqNameAndReferenceNumberToTableRowId
}: {
  majorWbsMap: Record<string, XmlElement>;
  boqNameAndReferenceNumberToTableRowId: Record<string, string>;
}): {
  rows: UpsertTableRow[];
  fieldReferenceIdToId: Record<string, string>;
} => {
  const fieldReferenceIdToId = Object.values(COST_CALCULATION_TABLE_FIELDS).reduce(
    (acc, field) => {
      acc[field.id] = typeidUnboxed('field');
      return acc;
    },
    {} as Record<CostCalculationTableField, string>
  );
  const isWBSItem = (item: any): item is WBSItem =>
    item.type === 'Position' && 'outlineSpecs' in item && 'estDetails' in item;

  return {
    fieldReferenceIdToId,
    rows: Object.entries(majorWbsMap)
      .flatMap(([wbsName, wbs]) => {
        const { items } = getItemsFromWbs(wbs);
        if (items.length === 0) {
          return [];
        }
        const wbsItems = items.filter(item => isWBSItem(item));
        return wbsItems.map(item => ({ ...item, wbsName }));
      })
      .flatMap(({ wbsName, ...item }) => {
        const wbsItemReferenceNumber = cleanReferenceNumber(item.name!);
        const boqName = wbsName;
        const key = `${boqName}-${wbsItemReferenceNumber}`;
        const parentTableRowId = boqNameAndReferenceNumberToTableRowId[key];

        if (parentTableRowId) {
          return convertWbsItemToRows({
            wbsItem: item,
            parentTableRowId
          });
        } else {
          return [];
        }
      })
      .map(({ id, parentRowId, parentTableRowId, ...row }) => {
        return {
          ...Object.fromEntries(
            Object.entries(row).map(([key, value]) => [
              fieldReferenceIdToId[key as CostCalculationTableField],
              value
            ])
          ),
          id,
          parentRowId: parentRowId ?? null,
          parentTableRowId: parentTableRowId ?? null
        };
      })
  };
};

type CostCalculationUpsertTableRow = UpsertTableRow &
  Record<CostCalculationTableField, TableRowPrimitiveDataType>;

const convertWbsItemToRows = ({
  wbsItem,
  parentTableRowId
}: {
  wbsItem: WBSItem;
  parentTableRowId: string;
}): CostCalculationUpsertTableRow[] => {
  const convertCoCDetail = (
    cocDetail: WBSItemEstDetailsCoCDetail,
    parent: {
      item: WBSItemEstDetailsItem | null;
      rowId: string;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow => {
    return {
      id: typeidUnboxed('row'),
      parentRowId: parent.rowId,
      parentTableRowId,
      type: 'CoCDetail',
      key: cocDetail.key ?? null,
      name: cocDetail.name ?? null,
      identifyKey: cocDetail.identifyKey ?? null,
      isDisabled: cocDetail.isDisabled ?? null,
      currency: cocDetail.currency ?? null,
      quantity: cocDetail.quantity ?? null,
      factor: cocDetail.factor ?? null,
      factorIsPerformanceFactor: cocDetail.factorIsPerformanceFactor ?? null,
      costPerUnit: cocDetail.urValue ?? null,
      costFactor: cocDetail.costFactor ?? null,
      cFactorCoc: cocDetail.cFactorCoc ?? null,
      qFactorCoc: cocDetail.qFactorCoc ?? null,
      flagFixedBudget: cocDetail.flagFixedBudget ?? null,
      budgetUomItem: cocDetail.budgetUomItem ?? null,
      budget: cocDetail.budget ?? null,
      unit: parent.item?.type === 'SubItem' ? (parent.item?.unitOfMeasure ?? null) : null,
      otherXmlFieldsAsJson: cocDetail.otherXmlFieldsAsJson ?? null,
      // SubItem fields
      compressed: null,
      referenceNumber: null,
      sItemLSum: null,
      sItemLSumAbs: null,
      sItemReserve: null,
      spPhase: null,
      sItemNo: null
    };
  };

  const convertCommodityDetail = (
    commodityDetail: WBSItemEstDetailsCommodityDetail,
    parent: {
      item: WBSItemEstDetailsItem | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    const rowId = typeidUnboxed('row');

    return [
      {
        id: rowId,
        parentRowId: parent.rowId,
        parentTableRowId,
        type: 'CommodityDetail',
        key: commodityDetail.nameCommodity ?? null,
        name: commodityDetail.descrCommodity ?? null,
        budgetUomItem: commodityDetail.budgetUomItem ?? null,
        budget: commodityDetail.budget ?? null,
        quantity: commodityDetail.quantity ?? null,
        factor: commodityDetail.factor ?? null,
        factorIsPerformanceFactor: commodityDetail.factorIsPerformanceFactor ?? null,
        costFactor: commodityDetail.costFactor ?? null,
        costPerUnit: commodityDetail.urValue ?? null,
        currency: commodityDetail.currency ?? null,
        identifyKey: commodityDetail.identifyKey ?? null,
        isDisabled: commodityDetail.isDisabled ?? null,
        flagFixedBudget: commodityDetail.flagFixedBudget ?? null,
        otherXmlFieldsAsJson: commodityDetail.otherXmlFieldsAsJson ?? null,
        cFactorCoc: null,
        qFactorCoc: null,
        unit: null,
        compressed: null,
        referenceNumber: null,
        sItemLSum: null,
        sItemLSumAbs: null,
        sItemReserve: null,
        spPhase: null,
        sItemNo: null
      },
      ...(commodityDetail.estDetails?.items
        ? convertToRows(
            commodityDetail.estDetails?.items,
            {
              item: {
                ...commodityDetail,
                type: 'CommodityDetail' as const
              },
              rowId
            },
            parentTableRowId
          )
        : [])
    ];
  };

  const convertSubItem = (
    subItem: WBSItemEstDetailsSubItem,
    parent: {
      item: WBSItemEstDetailsSubItem | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    const rowId = typeidUnboxed('row');
    return [
      {
        id: rowId,
        parentRowId: parent.rowId,
        parentTableRowId,
        type: 'SubItem',
        quantity: subItem.quantity ?? null,
        factor: subItem.factor ?? null,
        factorIsPerformanceFactor: subItem.factorIsPerformanceFactor ?? null,
        costFactor: subItem.costFactor ?? null,
        flagFixedBudget: subItem.flagFixedBudget ?? null,
        budgetUomItem: subItem.budgetUomItem ?? null,
        budget: subItem.budget ?? null,
        name: subItem.text ?? null,
        unit: subItem.unitOfMeasure ?? null,
        sItemNo: subItem.sItemNo ?? null,
        sItemLSum: subItem.sItemLSum ?? null,
        sItemLSumAbs: subItem.sItemLSumAbs ?? null,
        isDisabled: subItem.sItemDisabled ?? null,
        compressed: subItem.compressed ?? null,
        sItemReserve: subItem.sItemReserve ?? null,
        spPhase: subItem.spPhase ?? null,
        referenceNumber: subItem.subItemNumber ?? null,
        otherXmlFieldsAsJson: subItem.otherXmlFieldsAsJson ?? null,
        // CoCDetail fields
        key: null,
        identifyKey: null,
        currency: null,
        cFactorCoc: null,
        costPerUnit: null,
        qFactorCoc: null
      },
      ...(subItem.estDetails?.items
        ? convertToRows(
            subItem.estDetails?.items,
            {
              item: {
                ...subItem,
                type: 'SubItem' as const
              },
              rowId
            },
            parentTableRowId
          )
        : [])
    ];
  };

  const convertEstTextElement = (
    estTextElement: WBSItemEstDetailsEstTextElement,
    parent: {
      item: WBSItemEstDetailsItem | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    return [
      {
        id: typeidUnboxed('row'),
        parentRowId: parent.rowId,
        parentTableRowId,
        type: 'EstTextElement',
        name: estTextElement.text ?? null,
        otherXmlFieldsAsJson: estTextElement.otherXmlFieldsAsJson ?? null,
        budget: null,
        budgetUomItem: null,
        costFactor: null,
        costPerUnit: null,
        currency: null,
        flagFixedBudget: null,
        identifyKey: null,
        isDisabled: null,
        key: null,
        cFactorCoc: null,
        qFactorCoc: null,
        unit: null,
        compressed: null,
        referenceNumber: null,
        sItemLSum: null,
        sItemLSumAbs: null,
        sItemReserve: null,
        spPhase: null,
        sItemNo: null,
        factor: null,
        factorIsPerformanceFactor: null,
        quantity: null
      }
    ];
  };

  const convertToRows = (
    items: WBSItemEstDetailsItem[],
    parent: {
      item: WBSItemEstDetailsItem | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    return items
      .flatMap((item): CostCalculationUpsertTableRow[] => {
        if (item.type === 'CoCDetail') {
          return [
            convertCoCDetail(
              item,
              {
                item: parent.item,
                rowId: parent.rowId!
              },
              parentTableRowId
            )
          ];
        } else if (item.type === 'SubItem') {
          return convertSubItem(
            item,
            {
              item: parent.item as WBSItemEstDetailsSubItem | null,
              rowId: parent.rowId
            },
            parentTableRowId
          );
        } else if (item.type === 'CommodityDetail') {
          return convertCommodityDetail(
            item,
            {
              item: parent.item,
              rowId: parent.rowId
            },
            parentTableRowId
          );
        } else if (item.type === 'EstTextElement') {
          return convertEstTextElement(
            item,
            {
              item: parent.item,
              rowId: parent.rowId
            },
            parentTableRowId
          );
        } else {
          return [];
        }
      })
      .filter(removeNullish);
  };

  const subItems = wbsItem.estDetails?.items || [];

  if (subItems.length === 0) {
    return [];
  }

  const rows = convertToRows(
    subItems,
    {
      item: null,
      rowId: null
    },
    parentTableRowId
  );

  return rows;
};

const convertGaebFileToTableRows = ({
  gaebJson,
  fieldReferenceIdToId,
  boqName
}: {
  gaebJson: GAEB83;
  fieldReferenceIdToId: Record<WorkItemTableField, string>;
  boqName: string;
}): UpsertTableRow[] => {
  const boqNameRow: UpsertTableRow = {
    id: typeidUnboxed('row'),
    parentRowId: null,
    parentTableRowId: null,
    [fieldReferenceIdToId.boqName]: boqName,
    [fieldReferenceIdToId.type]: 'BoQ',
    [fieldReferenceIdToId.referenceNumber]: null,
    [fieldReferenceIdToId.shortText]: boqName,
    [fieldReferenceIdToId.longText]: null,
    [fieldReferenceIdToId.quantity]: null,
    [fieldReferenceIdToId.unit]: null
  };

  const convertItems = (
    items: BoQItem[] | BoQItem | undefined,
    parent: {
      referenceNumber: string;
      rowId: string | null;
    }
  ): UpsertTableRow[] => {
    if (!items) return [];
    items = Array.isArray(items) ? items : [items];
    return items.map(item => {
      const referenceNumber = getReferenceNumber(
        parent.referenceNumber,
        item._attributes.RNoPart,
        item._attributes.RNoIndex
      );
      return {
        id: typeidUnboxed('row'),
        parentRowId: parent.rowId,
        parentTableRowId: null,
        [fieldReferenceIdToId.boqName]: boqName,
        [fieldReferenceIdToId.type]: 'Position',
        [fieldReferenceIdToId.referenceNumber]: referenceNumber,
        [fieldReferenceIdToId.shortText]: removeHtmlTags(
          convertJsonToHtml(item.Description.CompleteText.OutlineText.OutlTxt.TextOutlTxt)
        ),
        [fieldReferenceIdToId.longText]: removeImageTags(
          convertJsonToHtml(item.Description.CompleteText.DetailTxt.Text)
        ),
        [fieldReferenceIdToId.quantity]: convertToNumber(item.Qty._text) ?? null,
        [fieldReferenceIdToId.unit]: item.QU._text
      };
    });
  };

  const convertCategories = (
    categories: BoQCtgy[] | BoQCtgy | undefined,
    parent: {
      referenceNumber: string;
      rowId: string | null;
    }
  ): UpsertTableRow[] => {
    if (!categories) return [];
    categories = Array.isArray(categories) ? categories : [categories];
    return categories.flatMap((category): UpsertTableRow[] => {
      const categoryRow = {
        id: typeidUnboxed('row'),
        parentRowId: parent.rowId,
        parentTableRowId: null,
        [fieldReferenceIdToId.boqName]: boqName,
        [fieldReferenceIdToId.type]: 'Category',
        [fieldReferenceIdToId.referenceNumber]: getReferenceNumber(
          parent.referenceNumber,
          category._attributes.RNoPart
        ),
        [fieldReferenceIdToId.shortText]: removeHtmlTags(convertJsonToHtml(category.LblTx)),
        [fieldReferenceIdToId.longText]: null,
        [fieldReferenceIdToId.quantity]: null,
        [fieldReferenceIdToId.unit]: null
      };

      return [
        categoryRow,
        ...convertItems(category.BoQBody.Itemlist?.Item, {
          referenceNumber: categoryRow[fieldReferenceIdToId.referenceNumber] ?? '',
          rowId: categoryRow.id
        }),
        ...convertCategories(category.BoQBody.BoQCtgy, {
          referenceNumber: categoryRow[fieldReferenceIdToId.referenceNumber] ?? '',
          rowId: categoryRow.id
        })
      ];
    });
  };

  const categoryRows = convertCategories(gaebJson.GAEB.Award.BoQ.BoQBody.BoQCtgy ?? [], {
    referenceNumber: '',
    rowId: null
  });

  return [
    boqNameRow,
    ...categoryRows.map(row => ({
      ...row,
      parentRowId: row.parentRowId === null ? boqNameRow.id : (row.parentRowId ?? null)
    }))
  ];
};

const getReferenceNumber = (
  parentReferenceNumber: string,
  referenceNumberPart: string,
  referenceIndex?: string
): string => {
  const referenceNumber = `${parentReferenceNumber.length === 0 ? '' : `${parentReferenceNumber}.`}${referenceNumberPart}`;

  if (referenceIndex) {
    return `${referenceNumber}.${referenceIndex}`;
  }

  return referenceNumber;
};
