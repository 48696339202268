'use client';

import { Combobox as ArkCombobox } from '@ark-ui/react/combobox';
import {
  type HTMLChakraProps,
  type SlotRecipeProps,
  type UnstyledProp,
  createSlotRecipeContext
} from '@chakra-ui/react';

type ComboboxSize = 'sm' | 'md' | 'lg';

////////////////////////////////////////////////////////////////////////////////////

const {
  withProvider,
  withContext,
  useStyles: useComboboxStyles
} = createSlotRecipeContext({ key: 'combobox' });

export { useComboboxStyles };

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxRootProps
  extends HTMLChakraProps<'div', ArkCombobox.RootBaseProps<{}>>,
    SlotRecipeProps<'combobox'>,
    UnstyledProp {
  size?: ComboboxSize;
}

export const ComboboxRoot = withProvider<HTMLDivElement, ComboboxRootProps>(
  ArkCombobox.Root,
  'root',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxLabelProps extends HTMLChakraProps<'label', ArkCombobox.LabelBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxLabel = withContext<HTMLLabelElement, ComboboxLabelProps>(
  ArkCombobox.Label,
  'label',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxControlProps extends HTMLChakraProps<'div', ArkCombobox.ControlBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxControl = withContext<HTMLDivElement, ComboboxControlProps>(
  ArkCombobox.Control,
  'control',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxInputProps extends HTMLChakraProps<'input', ArkCombobox.InputBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxInput = withContext<HTMLInputElement, ComboboxInputProps>(
  ArkCombobox.Input,
  'input',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxTriggerProps
  extends HTMLChakraProps<'button', ArkCombobox.TriggerBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxTrigger = withContext<HTMLButtonElement, ComboboxTriggerProps>(
  ArkCombobox.Trigger,
  'trigger',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxClearTriggerProps
  extends HTMLChakraProps<'button', ArkCombobox.ClearTriggerBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxClearTrigger = withContext<HTMLButtonElement, ComboboxClearTriggerProps>(
  ArkCombobox.ClearTrigger,
  'clearTrigger',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxPositionerProps
  extends HTMLChakraProps<'div', ArkCombobox.PositionerBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxPositioner = withContext<HTMLDivElement, ComboboxPositionerProps>(
  ArkCombobox.Positioner,
  'positioner',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxContentProps extends HTMLChakraProps<'div', ArkCombobox.ContentBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxContent = withContext<HTMLDivElement, ComboboxContentProps>(
  ArkCombobox.Content,
  'content',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxListProps extends HTMLChakraProps<'div', ArkCombobox.ListBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxList = withContext<HTMLDivElement, ComboboxListProps>(
  ArkCombobox.List,
  'list',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxItemGroupProps
  extends HTMLChakraProps<'div', ArkCombobox.ItemGroupBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxItemGroup = withContext<HTMLDivElement, ComboboxItemGroupProps>(
  ArkCombobox.ItemGroup,
  'itemGroup',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxItemGroupLabelProps
  extends HTMLChakraProps<'div', ArkCombobox.ItemGroupLabelBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxItemGroupLabel = withContext<HTMLDivElement, ComboboxItemGroupLabelProps>(
  ArkCombobox.ItemGroupLabel,
  'itemGroupLabel',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxItemProps extends HTMLChakraProps<'div', ArkCombobox.ItemBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxItem = withContext<HTMLDivElement, ComboboxItemProps>(
  ArkCombobox.Item,
  'item',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxItemTextProps
  extends HTMLChakraProps<'div', ArkCombobox.ItemTextBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxItemText = withContext<HTMLDivElement, ComboboxItemTextProps>(
  ArkCombobox.ItemText,
  'itemText',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export interface ComboboxItemIndicatorProps
  extends HTMLChakraProps<'div', ArkCombobox.ItemIndicatorBaseProps> {
  size?: ComboboxSize;
}

export const ComboboxItemIndicator = withContext<HTMLDivElement, ComboboxItemIndicatorProps>(
  ArkCombobox.ItemIndicator,
  'itemIndicator',
  { forwardAsChild: true }
);

////////////////////////////////////////////////////////////////////////////////////

export const ComboboxContext = ArkCombobox.Context;
