'use client';

import { ButtonProps, Flex } from '@chakra-ui/react';
import React from 'react';
import { Button, LinkButton } from '../ui';

interface TabButtonGroupProps {
  activeTabIndex: number;
  onChange?: (index: number) => void;
  tabs: {
    id: string;
    button: {
      text: string;
      icon?: React.ReactNode;
    };
    href?: string;
  }[];
}

export const TabButtonGroup = ({ tabs, onChange, activeTabIndex }: TabButtonGroupProps) => {
  return (
    <Flex gap={3} h={'full'} align={'center'}>
      {tabs.map((tab, index) => (
        <TabButton
          key={tab.id}
          isActive={index === activeTabIndex}
          href={tab.href}
          text={tab.button.text}
          icon={tab.button.icon}
          onClick={() => onChange?.(index)}
        />
      ))}
    </Flex>
  );
};

const TabButton = ({
  text,
  href,
  onClick,
  isActive,
  icon
}: {
  text: string;
  icon: React.ReactNode | undefined;
  href: string | undefined;
  onClick: (() => void) | undefined;
  isActive: boolean;
}) => {
  const commonProps: ButtonProps = {
    onClick: onClick,
    variant: isActive ? 'solid' : 'subtle',
    colorPalette: 'border',
    size: 'sm'
  };
  return href ? (
    <LinkButton href={href} {...commonProps}>
      {icon}
      {text}
    </LinkButton>
  ) : (
    <Button {...commonProps}>
      {icon}
      {text}
    </Button>
  );
};
