import { z } from 'zod';

export const chunkTypeSchema = z.enum(['TABLE', 'DOCUMENT', 'CONVERSATION', 'PROJECT']);
export type ChunkType = z.infer<typeof chunkTypeSchema>;

export const chunkDtoSchema = z
  .object({
    id: z.string(),
    text: z.string(),
    type: chunkTypeSchema,
    pieceOfInformationId: z.string()
  })
  .and(
    z.discriminatedUnion('type', [
      z.object({
        type: z.literal('TABLE'),
        tableId: z.string(),
        rowId: z.string(),
        fieldId: z.string()
      }),
      z.object({
        type: z.literal('DOCUMENT')
      }),
      z.object({
        type: z.literal('CONVERSATION'),
        conversationId: z.string(),
        conversationNodeId: z.string(),
        conversationNodeElementId: z.string()
      }),
      z.object({
        type: z.literal('PROJECT'),
        projectId: z.string()
      })
    ])
  );

export type ChunkDto = z.infer<typeof chunkDtoSchema>;
