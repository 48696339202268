import { Input, InputProps } from '@chakra-ui/react';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { FieldValues } from 'react-hook-form';

export const NumberInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  placeholder,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  autoFocus,
  size = 'md',
  backgroundColor = 'white'
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder: InputProps['placeholder'];
  backgroundColor?: InputProps['backgroundColor'];
}) => {
  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={label}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Input
        type={'number'}
        placeholder={placeholder}
        size={size}
        autoFocus={autoFocus}
        backgroundColor={backgroundColor}
        {...form.register(name, {
          setValueAs: (v: string) => (v === '' ? undefined : parseInt(v))
        })}
      />
    </FormInput>
  );
};
