export type XmlDocument = {
  declaration?: {
    attributes: {
      version: string;
    };
  };
  elements: XmlNode[];
};

export type XmlTextElement = {
  type: 'text';
  text: string;
};

export type XmlElement = {
  type: 'element';
  name: string;
  attributes?: Record<string, string>;
  elements?: XmlNode[];
};

export type XmlNode = {
  type: 'element' | 'text';
} & (XmlElement | XmlTextElement);

export const findXmlElementByName = (
  elements: XmlNode[] | undefined,
  name: string
): XmlElement | undefined => {
  return elements?.find(
    (element): element is XmlElement => element.type === 'element' && element.name === name
  );
};

export abstract class XmlConverter<T> {
  abstract toJson(xml: XmlNode | undefined): T | undefined;
  abstract toXml(json: T): XmlNode;
}

export class TextXmlConverter extends XmlConverter<string> {
  toJson(xml: XmlNode | undefined): string | undefined {
    if (xml?.type === 'text') {
      return xml.text;
    }
    return undefined;
  }
  toXml(value: string): XmlNode {
    return { type: 'text', text: value };
  }
}

export const textXmlConverter = new TextXmlConverter();
