import TurndownService, { Rule } from 'turndown';
import { js2xml, xml2js } from 'xml-js';
import { formatMention } from '../mentions';

export const convertJsonToHtml = (json: Record<string, unknown>) => {
  const xmlString = js2xml(json, { compact: true, spaces: 4 });
  return xmlString;
};

export const removeXmlTags = (html: string) => {
  return html
    .replace(/<br\s*\/?>/gi, ' ')
    .replace(/<[^>]*>?/g, '')
    .trim();
};

// Works for base64 images
// <p>Some text</p>
// <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUA" alt="base64 image">
// <image width="320" Type="image/png" Encoding="base64">iVBORw0KGgoAAAANSUhEUgAAAUAAAAHJCAIAAAB7TXftAAAABmJLR0QA/wD/AP+gvaeTAAAA</image>
// <img src="https://example.com/image.jpg" alt="regular image">
// <p>More text</p>
export const removeImageTags = (html: string) => {
  const base64ImageTagRegex =
    /<img[^>]*src=["']data:image\/[^;]+;base64,[^"']*["'][^>]*>|<image[^>]*Encoding=["']base64["'][^>]*>[^<]*<\/image>/gi;

  return html.replace(base64ImageTagRegex, '');
};

const htmlTags = [
  'a',
  'abbr',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'base',
  'bdi',
  'bdo',
  'blockquote',
  'body',
  'br',
  'button',
  'canvas',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'data',
  'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dialog',
  'div',
  'dl',
  'dt',
  'em',
  'embed',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hr',
  'html',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'legend',
  'li',
  'link',
  'main',
  'map',
  'mark',
  'meta',
  'meter',
  'nav',
  'noscript',
  'object',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'picture',
  'pre',
  'progress',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'script',
  'section',
  'select',
  'small',
  'source',
  'span',
  'strong',
  'style',
  'sub',
  'summary',
  'sup',
  'svg',
  'table',
  'tbody',
  'td',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'u',
  'ul',
  'var',
  'video',
  'wbr'
];

export const removeNonHtmlTags = (xmlString: string) => {
  const result = xml2js(xmlString, { compact: false });

  function filterHtmlElements<TNode extends { type: string; name: string; elements: TNode[] }>(
    node: TNode
  ) {
    if (node.type === 'element') {
      if (htmlTags.includes(node.name.toLowerCase())) {
        if (node.elements) {
          node.elements = node.elements.filter(filterHtmlElements);
        }
        return true;
      }
      return false;
    }
    return true;
  }

  result.elements = result.elements.filter(filterHtmlElements);
  return js2xml(result, { compact: false, spaces: 2 });
};

export const removeHtmlTags = (html: string) => {
  return html
    .replace(/<br\s*\/?>/gi, '\n')
    .replace(/<[^>]*>?/g, '')
    .trim();
};

type Rules = 'conversationMemberMention' | 'pieceOfInformationMention' | 'taskMention';

const rulesMap: Record<Rules, Rule> = {
  conversationMemberMention: {
    filter: node => {
      return (
        node.nodeName === 'SPAN' &&
        node.classList.contains('mention') &&
        node.classList.contains('conversation-member-mention')
      );
    },
    replacement: (content, node) => {
      const typedNode = node as HTMLElement;
      const dataId = typedNode.getAttribute('data-id')!;
      const dataLabel = typedNode.getAttribute('data-label')!;
      return formatMention({ type: 'conversationMember', id: dataId, label: dataLabel });
    }
  },
  pieceOfInformationMention: {
    filter: node => {
      return (
        node.nodeName === 'SPAN' &&
        node.classList.contains('mention') &&
        node.classList.contains('piece-of-information-mention')
      );
    },
    replacement: (content, node) => {
      const typedNode = node as HTMLElement;
      const dataId = typedNode.getAttribute('data-id')!;
      const dataLabel = typedNode.getAttribute('data-label')!;
      return formatMention({ type: 'pieceOfInformation', id: dataId, label: dataLabel });
    }
  },
  taskMention: {
    filter: node => {
      return (
        node.nodeName === 'SPAN' &&
        node.classList.contains('mention') &&
        node.classList.contains('task-mention')
      );
    },
    replacement: (content, node) => {
      const typedNode = node as HTMLElement;
      const dataId = typedNode.getAttribute('data-id')!;
      const dataLabel = typedNode.getAttribute('data-label')!;
      return formatMention({ type: 'task', id: dataId, label: dataLabel });
    }
  }
};
type ExtensionName = keyof typeof rulesMap;

export const htmlToMarkdown = (html: string, enabledExtensions?: ExtensionName[]) => {
  const converter = new TurndownService();
  enabledExtensions?.forEach(extension => {
    converter.addRule(extension, rulesMap[extension]);
  });
  const markdown = converter.turndown(html);
  return markdown.replace(/<\/?[^>]+(>|$)/g, ''); // Strip any remaining HTML tags
};
