import { z } from 'zod';
import { projectTagNameSchema } from '../tag';
import { projectAdditionalInformationSchema, projectTypeSchema } from '../project';

//
// Get similar projects
//

export const getSimilarProjectsSchema = z.object({
  projectId: z.string(),
  tagName: projectTagNameSchema.optional(),
  limit: z.number().optional()
});
export type GetSimilarProjectsArgs = z.infer<typeof getSimilarProjectsSchema>;

export const getSimilarProjectsOutputSchema = z.array(
  z.object({
    id: z.string(),
    type: projectTypeSchema,
    name: z.string(),
    description: z.string().nullable(),
    additionalInformation: projectAdditionalInformationSchema.nullable(),
    pieceOfInformationId: z.string()
  })
);
export type GetSimilarProjectsOutput = z.infer<typeof getSimilarProjectsOutputSchema>;
